import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { CategoryProps, SubMenuProps } from '../interface/Interface';

import DynamicIcon from './DynamicIcon';

import { nowMenuState, nowMenuNameState } from '../state/store';


const MenuItem = ({ category, subMenu } : { category:Array<CategoryProps>, subMenu:Array<SubMenuProps> }) => {

    // recoil
    const [ menuState, setMenuState ] = useRecoilState(nowMenuState);
    const [ , setMenuNameState ] = useRecoilState(nowMenuNameState);

    // before select menu
    const [ beforeSelectMenu, setSelectMenu ] = useState<HTMLDivElement>();

    // menu box is open : false - close | true - open
    const [ isMenuOpen, setMenuOpen ] = useState(false);

    // category menu refs
    const menuRefs = useRef<null[] | HTMLDivElement[]>([]);

    // sub menu refs
    const subMenuRefs = useRef<null[] | HTMLDivElement[]>([]);

    // 

    // Handle : click menu
    const MenuClickHandle = (type:string, name?:string,isList?:string) => {

        // Menu have List? : list is Component Name
        // Must Need List Value
        // 카테고리 메뉴 자체 클릭 메뉴로 활용 할 수 있기 때문에 메뉴 타입에 따른 Refs 분기처리
        const refsPointer = type === 'menu' ? menuRefs : subMenuRefs;

        if(isList) { // have list value check

            if(refsPointer.current !== null) { // refs null check

                refsPointer.current.map((el) => {

                    let classCount:number = el!.classList.length;

                    for (let index = 0; index < classCount; index++) { 

                        if(isList === el!.classList[index]) { // class name check

                            if(beforeSelectMenu) { // old check menu background change none

                                beforeSelectMenu.setAttribute('style', 'border-radius: none; background-color: none');

                            }

                            setSelectMenu(el!); // new check menu
                            el?.setAttribute('style', 'border-radius: 5px; background-color: #383b3e'); // change css
                        }
                    }
                })
            }
            
            // 메뉴를 선택하는 시점부터 브라우저 내 선택된 메뉴에 대한 정보를 저장한다.
            window.localStorage.setItem('menu', isList);
            window.localStorage.setItem('menu_name', name!);
            
            setMenuNameState(name!)
            setMenuState(isList); // recoil: select now menu
            selectMenu(); // css change
        }
    }
    
    let beforeOpenMenuBox:any = null;

    // 메뉴 박스 오픈/닫기 처리
    const FoldingHandle = (e:any) => {

        if(isMenuOpen) {

            setMenuOpen(false);

        } else {

            setMenuOpen(true);

        }
    }

    const selectMenu = () => {

        const menuRefsMix = [
            ...menuRefs.current,
            ...subMenuRefs.current,
        ];

        menuRefsMix.map((item) => {

            const classList = item?.classList;
            classList?.forEach((i) => {
                if ( i === menuState ) {
                    item?.classList.add('selectMenu');
                } else {
                    item?.classList.remove('selectMenu');
                }
            })
        });

    }

    let menu = category.map((menu, index) => {

        if ( menu.id === 0 ) {

            return  <MenuSection key={index}><span>{menu.name}</span></MenuSection>

        } else {

            return  <MenuBox key={index}>

                        {/* Menu Title */}
                        <MenuHeader 
                            className={menu.list}
                            onClick={(e) => menu.list? MenuClickHandle('menu', menu.name, menu.list) : FoldingHandle(e)}
                            ref={ el => menuRefs.current[index] = el }
                        >
                            <DynamicIcon icon={menu.icon!} size="1em" color={'white'} />
                            <span>{menu.name}</span>
                            
                            {/* { menu.list? 
                                "" 
                                :
                                <DynamicIcon className='folding' icon={!isMenuOpen? 'io/IoIosArrowUp' : 'io/IoIosArrowDown'} size="1em" color={'white'} /> 
                            } */}
                            
                        </MenuHeader>
                        
                        { subMenu.map((sub, idx) => {

                            if(sub.category == menu.id) {

                                // Sub Menu
                                return  <SubMenu
                                            key={idx}
                                            className={sub.list}
                                            onClick={() => MenuClickHandle('sub', sub.name, sub.list)}
                                            ref={ el => subMenuRefs.current[idx] = el }
                                        >
                                            { sub.icon ? <DynamicIcon icon={sub.icon!} size="1em" color={'white'} /> : ""} 
                                            <span>{sub.name}</span>
                                        </SubMenu>
                                // End Return
                            }
                        }) }

                    </MenuBox>
        }
    });

    useEffect(() => {

        selectMenu();

    }, [menuState]);
    
    //Menu list : LnbMenu.tsx
    return (
        <Container>
            {menu}
        </Container>

    // End Return
    );
    

}

export default React.memo(MenuItem);

// 메뉴 전체 섹션 (사명과 세팅/로그아웃 영역 제외)
const Container = styled.div`

    width: 100%;
    // height: 95%;

    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

// 클릭이 가능한 실제 메뉴
const MenuBox = styled.div`

    width: calc(100% - 30px);
    min-height: 40px;
    height: 100%;
    // height: auto;
    overflow: hidden;
        
    position: relative;
    display: flex;
    flex-direction: column;

    align-items: center;

    color: white;
    font-weight: bold;
    font-size: 12px;

    margin-bottom: 15px;

    span {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    svg { width: 15px; margin-right: 15px; }

    path { stroke: currentcolor; }

    .folding {
        position: absolute;
        right: 0;
    }

    .selectMenu {

        border-radius: 5px; background-color: #383b3e;

    }

`;

// 섹션 구분을 위한 메뉴
const MenuSection = styled.div`

    width: calc(100% - 30px);
    height: 40px;

    display: flex;
    align-items: center;

    margin-top: 20px;
    margin-bottom: 20px;

    color: gray;
    font-size: 1.0rem;
    font-weight: bold;

    padding-left: 20px;

`;

// 하위 메뉴가 존재하는 제목 타이틀 영역
const MenuHeader = styled.div`

    width: 100%;
    min-height: 40px;
    height: 40px;
    margin-bottom: 10px;
    padding-left: 20px;
    display: flex;
    align-items: center;

    &:hover {

        cursor: pointer;

    }

`;


// 하위 메뉴 디자인을 위한 메뉴
const SubMenu = styled.div`

    width: calc(100% - 30px);
    height: 40px;

    display: flex;
    align-items: center;

    padding-left: 30px;
    margin-bottom: 5px;



    &:hover {
        cursor: pointer;
        color:#ffffff;
        border-radius: 5px;
        background-color: #383b3e;
    }
    
`;