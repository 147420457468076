import React from 'react';
import styled from 'styled-components';
import SettingIcon from '../assets/icons/humbleicon.svg';
import {FiSettings} from 'react-icons/fi';

const MenuSetting = () => {

    return (
        <Container>
            <span>Setting</span>
            <FiSettings />
        </Container>
    );

}

export default MenuSetting;

const Container = styled.div`
    width: 50%;
    // background-color: #1f2325;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    FileSettings {

    }

    span {
        font-weight: bold;
        font-size: 12px;
        margin-right: 5px;
    }
`;