import React from 'react';
import styled from 'styled-components';
import {FiLogOut} from 'react-icons/fi';

const Logout = () => {

    const LogoutHandle = () => {
        localStorage.setItem("access_token", '');
        localStorage.setItem("refresh_token", '');
        window.location.href = "/";
    }

    return (
        <Container onClick={LogoutHandle}>
            <span>Logout</span>
            <FiLogOut />
        </Container>
    );

}

export default Logout;

const Container = styled.div`
    width: 50%;
    // background-color: #1f2325;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    span {
        font-weight: bold;
        font-size: 12px;
        margin-right: 5px;
    }
`;