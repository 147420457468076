import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { deleteCertificate, deleteFaq, 
    deleteProduct, 
    deleteProductCertificate, 
    deleteSnsInProduct, 
    registerFaq, 
    registerProductCertificate, 
    registerSnsInProduct, 
    updateCertificate, 
    updateProduct, 
    updateProductInSocialsImage,  
    useDetailProduct 
} from "../../api/Apis";

import { ListType } from "../../interface/Interface";
import emptyImage from "../../assets/images/empty_image.png";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import axios, { AxiosError } from "axios";

const Certifiacate = ( data:ListType ) => {

    const [ type, setType ] = useState(data.type || "");
    const [ register_date, setRegisterDate ] = useState(data.register_date || "");
    const [ doc_status, setDocStatus ] = useState(data.doc_status || "");
    const [ number, setNumber ] = useState(data.number || "");
    const [ content, setContent ] = useState(data.content || "");

    const typeOnChangeHandle = (e:any) => { setType(e.target.value); }
    const registerDateOnChangeHandle = (e:any) => { setRegisterDate(e.target.value); }
    const docStatusOnChangeHandle = (e:any) => { setDocStatus(e.target.value); }
    const numberOnChangeHandle = (e:any) => { setNumber(e.target.value); }
    const contentOnChangeHandle = (e:any) => { setContent(e.target.value); }

    const deleteButtonHandle = () => {  

        if(window.confirm("정말로 삭제하시겠습니까?")) {
            deleteCertificate(data.id).then(res => {
                if(res.success) {
                    window.location.href = "/main";
                }
            });
        }

    };

    const saveButtonHandle = () => {  

        /* Call save(update) Api */  
        let updateData:any = [];

        updateData['id']              = data.id;
        updateData['type']            = type;
        updateData['register_date']   = register_date;
        updateData['number']          = number;
        updateData['doc_status']      = doc_status;
        updateData['number']          = number;
        updateData['content']         = content;


        updateCertificate(updateData).then(res => {
            if(res.success) {
                window.location.href = "/main";
            }
        });

    };

    useEffect(() => { 
   
        if(!data.id) {
            alert("상품 ID를 확인할 수 없습니다. [Product Detail Missing data.id]");
            window.location.href = "/main";
        }

    }, []);

    return (
        <Container>

            {/* Top Options & Info Section */}
            <Options>

                {/* Delete Button */}
                <DeleteButton
                    onClick={deleteButtonHandle}
                >
                    삭제
                </DeleteButton>

                <SaveButton
                    onClick={saveButtonHandle}
                >
                    저장
                </SaveButton>

                <h4></h4>
            </Options>

            <DataSection>

                {/* Data Id */}
                <Row height={40}>
                    <Col width={20}>고유번호</Col>
                    <Col width={80}>{ data.id }</Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>구분</Col>
                    <Col width={80}>
                        <input 
                            type="text" 
                            onChange={(e) => typeOnChangeHandle(e)} 
                            value={ type }
                        />  
                    </Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>일자</Col>
                    <Col width={80}>
                        <input 
                            type="text" 
                            onChange={(e) => registerDateOnChangeHandle(e)} 
                            placeholder="2021-09-05"
                            value={ register_date }
                        />
                    </Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>진행현황</Col>
                    <Col width={80}>
                        <input 
                            type="text" 
                            onChange={(e) => docStatusOnChangeHandle(e)} 
                            value={ doc_status }
                        />
                    </Col>
                </Row> 

                <Row height={40}>
                    <Col width={20}>인증번호</Col>
                    <Col width={80}>
                        <input 
                            type="text" 
                            onChange={(e) => numberOnChangeHandle(e)} 
                            value={ number }
                        />
                    </Col>
                </Row> 

                <Row height={40}>
                    <Col width={20}>인증 및 등록내용</Col>
                    <Col width={80}>
                        {/* enter */}
                        <textarea 
                            onChange={(e) => contentOnChangeHandle(e)}
                            value={content}
                        />
                    </Col>
                </Row> 

                {/* created_at */}
                <Row height={40}>
                    <Col width={20}>작성일</Col>
                    <Col width={80}>{ data.created_at }</Col>
                </Row>
                
            </DataSection>

        </Container>
    )
}

export default Certifiacate;

//
const Container = styled.div`

    width: 100%;
    min-width: 500px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

`;

//
const DataSection = styled.div`

    width: 98%;
    height: calc(100% - 40px);
    margin-top: 50px;
    overflow-y: auto;
    padding-top: 40px;
`;

//
const Row = styled.div<{ height: number }>`

    width: 100%;
    min-height: 40px;

    height: ${props => (props.height ? props.height+"px;" : "40px;" )}

    display: flex;
    flex-direction: row;
    
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 1rem;

`;

//
const Col = styled.div<{ width: number }>`

    width: ${props => (props.width ? props.width+"%;" : "10%;" )}

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: thin solid #ffffff6e;

    text-align: center;

    overflow-y: auto;

    .productImageAddBtn {
        width: 170px;
        height: 60px;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: bold;
        background-color: white;
        color: black;
    }

    img {

        width: 320px;
        border-radius: 2px;

    }

    video {

        width: 320px;
        border-radius: 2px;

    }

    textarea {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    input {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    span {

        margin-bottom: 20px;

    }

    select {

        width: 100%;
        height: 100%;
        border: none;
        text-align: center;
        background-color: #7c4c4c78;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        appearance: none;

    }

    .addBtn {
        width: 40%;
        height: 30px;
        margin-bottom: 5px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;

        font-weight: bold;
        font-size: 1rem;

        &:hover {
            cursor: pointer;
        }
    }

    .faqsContainer {

        width: 100%;
        height: 100%;
    
        .faqsBox {

            border: thin solid black;
            width: 100%;
            height: 200px;

            display: flex;
            flex-direction: row;

            margin-bottom: 5px;

            .label {

                width: 120px;
                display: flex;
                flex-direction: column;

                align-items: center;
                justify-content: center;

                background-color: black;

                button {

                    width: 60%;
                    height: 30px;
                    background-color: red;
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                    }
                }

            }

            .faqList {
                
                width: calc(100% - 120px);
                height: 200px;
                
                .faqs {
                    width: 100%;
                    height: 49%;
                    text-align: left;
                    padding-left: 5px;
                }
            }
            
        }

    }

    .certificateContainer {

        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;

        .selectList {

            color: white;

            width: 100%;
            height: 50%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-bottom: thin solid white;
            }

            span {
                
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 90%;
                
            }

            .listBox {
                
                width: 100%;
                height: 100%;
                overflow: auto;

                .list {

                    width: min-content;
                    min-width: 100%;
                    height: 100%;
                    
                    display: flex;
                    flex-direction: row;

                    justify-content: center;
                    align-items: center;

                    .certificateImage {

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        margin-left: 5px;
                        margin-right: 5px;

                        position: relative;

                        width: 240px;
                        min-width: 240px;
                        height: 90%;

                        img {
                            width: 100%;
                            height: 80%;

                        }

                        .selectbtn {
                            
                            width: 90px;
                            height: 30px;

                            border:none;
                            border-radius: 5px;
                            background-color: white;
                            color: black;
                            font-weight: bold;
                            font-size: 1rem;

                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            z-index: 9;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .deleteBtn {

                            width: 90px;
                            height: 30px;

                            border:none;
                            border-radius: 5px;
                            background-color: red;
                            color: white;
                            font-weight: bold;
                            font-size: 1rem;

                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            z-index: 9;

                            &:hover {
                                cursor: pointer;
                            }

                        }

                        .certificateImageBg {

                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            width: 240px;
                            min-width: 240px;
                            height: 80%;

                            background: gray;
                            opacity: 0.8;

                            display: flex;
                            white-space: pre-line;
                            
                            justify-content : center;
                            align-items: center;

                        }
                    }
                }
            }
        }

    }

    .snsContainer {

        width: 100%;
        height: 100%;

        .snsBox { 

            width: 100%;
            height: 140px;
            position: relative;

            display: flex;
            flex-direction: row;

            margin-bottom: 10px;

            .snsDelete {

                width: 120px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: black;

                button { 
                    width: 60%;
                    height: 30px;
                    background-color: red;
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-weight: bold;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .snsData {

                width: calc(100% - 120px);

                input {
                    height: calc(100% / 3);
                    font-size: 1rem;
                }

                .snsFile {

                    text-align: left;
                    width: 100%;
                    height: calc(100% / 3); 
                    padding-left: 20px;
                    
                    color: white;
                    font-size: 1rem;
                    font-weight: bold;

                    background-color: gray;

                    &:hover {
                        cursor: pointer;
                    }

                }

                .snsFileUploadBox {
                    
                    display: flex;
                    align-items: center;

                    width: 100%;
                    height: calc(100% / 3); 
                    color: white;
                    font-size: 1rem;
                    font-weight: bold;

                    background-color: gray;

                    input {
                        display: inline;
                        width: 100%;
                        height: auto;
                        background-color: transparent;
                        margin-left: 10px;
                    }

                    .initBtn {
                        border:none;
                        border-radius: 5px;
                        width: 60px;
                        height: 25px;
                        font-size: 0.9rem;
                        font-weight: bold;
                        background-color: #7c4a7f;
                        margin-left: 10px;
                        color: white;

                        &:hover {
                            cursor: pointer;
                        }

                    }

                }
            }

        }

    }
    
`;

// 
const Options = styled.div`

    width: 100%;
    height: 40px;
    min-height: 40px;
    position: fixed;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;

    h4 {
        margin-left: 20px;
        color: red;
    }
`;

//
const DeleteButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #43424c;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;

//
const SaveButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #4c486e;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;