type Key = { [key: string] : any };

// Defined Columns
export const columns: Key = {
    
    /*
        각 컬럼 별, 테이블 리스트 내 노출되어야 하는
        컬럼을 정의 할 수 있습니다.

        메뉴 별 컬럼 정의는 메뉴 정의 리스트 중 
        list value 로 매칭됩니다.
    */

    // min-width : 768px
    BANNERS: 
    {
        'id': "번호|5%",
        'fileAddr': "이미지(영상)|50%", 
        'type': "타입|10%", 
        'title': "제목|15%", 
        'content': "소제목|10%", 
        'created_at': "생성일|10%",
    },


    POPUPS: 
    {
        'id': "번호|5%", 
        'title': "제목|15%", 
        'link': "링크|20%", 
        'fileAddr': "이미지|50%", 
        'created_at': "생성일|10%",
    },


    INQUIRY: 
    {
        'id': "번호|5%", 
        'status': "상태|10%",
        'category': "분류|10%", 
        'name': "이름|10%", 
        'phone': "핸드폰|15%", 
        'company': "회사|15%",
        'email': "이메일|15%",
        'content': "내용|10%",
        'created_at': "생성일|10%",
    },

    HISTORYCATEGORY: 
    {
        'id': "번호|5%", 
        'year_start': "시작연도|25%", 
        'year_end': "마감연도|25%", 
        'content': "내용|35%", 
        'created_at': "생성일|10%",
    },


    HISTORIES: 
    {
        'id': "번호|5%", 
        'type': "범위|25%", 
        'year': "년도|15%", 
        'month': "월|15%", 
        'content': "내용|30%", 
        'created_at': "생성일|10%",
    },


    PRODUCTS: 
    {
        'id': "번호|5%", 
        'status': "상태|10%", 
        'tags': "태그|20%", 
        'sel_link': "구매링크|20%",
        'title': "제목|40%", 
        // 'content': "상세내용|20%", 
        'created_at': "생성일|10%",
    },


    PATENTS: 
    {
        'id': "번호|5%", 
        'country': "국가|10%",
        'type': "상태|10%", 
        'title': "특허명|25%",
        'number': "인증번호|15%", 
        'writer': "출원인|15%", 
        'register_date': "등록일|10%",
        'created_at': "생성일|10%",
    },


    CERTIFICATES: 
    {
        'id': "번호|5%", 
        'type': "구분|10%", 
        'doc_status': "현황|20%",  
        'number': "인증번호|20%", 
        'content': "내용|25%", 
        'register_date': "등록일|10%",
        'created_at': "생성일|10%",
    },


    ESG: 
    {
        'id': "번호|5%", 
        'type': "구분|10%", 
        'fileAddr': "이미지|40%",
        'content': "내용|25%", 
        'register_date': "등록일|10%",
        'created_at': "생성일|10%",
    },


    NEWS: 
    {
        'id': "번호|5%", 
        'status': "상태|10%", 
        'count': "조회수|10%", 
        'title': "제목|40%", 
        'content': "내용|25%", 
        'created_at': "생성일|10%",
    },


    NOTICE: 
    {
        'id': "번호|5%", 
        'status': "상태|10%", 
        'count': "조회수|10%", 
        'title': "제목|40%", 
        'content': "내용|25%", 
        'created_at': "생성일|10%",
    },


    SNS: 
    {
        'id': "번호|5%", 
        'type': "타입|20%", 
        'title': "제목|35%",
        'fileAddr': "이미지|30%", 
        'created_at': "생성일|10%",
    },

    PROCUREMENT:
    {
        'id': "번호|5%", 
        'title': "상품명|50%",
        'tags': "태그|35%", 
        'created_at': "생성일|10%",
    }

}