import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { language } from "../state/store";

const Header = () => {

    const divToggleRef = useRef<any>(null);    
    const [ type, setLanguage ] = useRecoilState(language);

    const languageToggleOnClickHandle = (select:string) => {

        if(divToggleRef.current) {            

            if(select === 'ko') divToggleRef.current.style.transform = 'translateX(0px)';
            if(select === 'en') divToggleRef.current.style.transform = 'translateX(80px)';

            window.localStorage.setItem('language', select);
            setLanguage(select);

            setTimeout(() => {
                window.location.reload();    
            }, 600);

        }

    }

    useEffect(() => {
        if(type === 'ko') {
            if(divToggleRef.current) divToggleRef.current.style.transform = 'translateX(0px)';
            window.localStorage.setItem('language', type);
        } else {
            if(divToggleRef.current) divToggleRef.current.style.transform = 'translateX(80px)';
            window.localStorage.setItem('language', type);
        }
    }, [])

    return (

        <Container>

            <div className="toggleBox">

                <div    
                    className="toggleInBox"
                    ref={divToggleRef}
                />

                <div 
                    className="koBox"
                    onClick={(e) => languageToggleOnClickHandle('ko')}
                >
                    <span>Ko</span>
                </div>

                <div 
                    className="enBox"
                    onClick={(e) => languageToggleOnClickHandle('en')}
                >
                    <span>En</span>
                </div>
            </div>

        </Container>
    )
}

export default Header;


const Container = styled.div`

    width: 100%;
    height: 50px;
    background-color: #202125;
    border-bottom: 0.1px solid #ffffff38;

    display: flex;
    flex-direction: row;
    align-items: center;

    .toggleBox {

        position: relative;
        margin-left: 30px;

        width: 160px;
        height: 30px;
        background-color: #3A3A3A;
        border-raidus: 5px;
        box-sha2dow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        display:flex;
        align-items: center;

        .toggleInBox {

            position: absolute;
            top: 0;
            width: 80px;
            height: 30px;
            background-color: #0080ff;
            border-radius: 5px;
            opacity: 0.2;

            transition: all 0.5s;

            &:hover {
                background-color: #0080ff; 
            }

        }

        .koBox {
            width: 50%;
            height: 100%;
            // background-color: red;
            display:flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 1rem;
            color: white;
            &:hover {
                cursor: pointer;
                color: red;
            }

        }

        .enBox {
            width: 50%;
            height: 100%;
            // background-color: blue;
            display:flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 1rem;
            color: white;
            &:hover {
                cursor: pointer;
                color: red;
            }
        }

    }
    
`;

const PwdSection = styled.div`
    width: 30%;

`;

const UserSection = styled.div`
    width: 70%;

`;