let type = window.localStorage.getItem("language") || 'ko';

export const BASE_URL = "https://api.bio3s.com/api/" + type + "/";

export const REFRESH = "https://api.bio3s.com/api/" + "refreshToken";


type Key = { [key: string] : any };

export const viewApi: Key = {
    BANNERS:        BASE_URL + "view/banner",
    POPUPS:         BASE_URL + "view/popup",
    INQUIRY:        BASE_URL + "view/qna",
    HISTORYCATEGORY: BASE_URL + "view/historyCategory",
    HISTORIES:      BASE_URL + "view/history",
    PRODUCTS:       BASE_URL + "view/product",
    PATENTS:        BASE_URL + "view/patent",
    CERTIFICATES:   BASE_URL + "view/certificate",
    ESG:            BASE_URL + "view/gallery",
    NEWS:           BASE_URL + "view/news",
    NOTICE:         BASE_URL + "view/notice",
    SNS:            BASE_URL + "view/sns",
    PROCUREMENT:    BASE_URL + "view/procurement",
}


export const deleteApi: Key = {
    BANNERS:        BASE_URL + "delete/banner",
    POPUPS:         BASE_URL + "delete/popup",
    INQUIRY:        BASE_URL + "delete/qna",
    HISTORIES:      BASE_URL + "delete/history",
    PRODUCTS:       BASE_URL + "delete/product",
    PATENTS:        BASE_URL + "delete/patent",
    CERTIFICATES:   BASE_URL + "delete/certificate",
    ESG:            BASE_URL + "delete/gallery",
    NEWS:           BASE_URL + "delete/news",
    NOTICE:         BASE_URL + "delete/notice",
    SNS:            BASE_URL + "delete/sns",
}


export const updateApi: Key = {
    BANNERS:        BASE_URL + "update/banner",
    POPUPS:         BASE_URL + "update/popup",
    INQUIRY:        BASE_URL + "update/qna",
    HISTORIES:      BASE_URL + "update/history",
    PRODUCTS:       BASE_URL + "update/product",
    PATENTS:        BASE_URL + "update/patent",
    CERTIFICATES:   BASE_URL + "update/certificate",
    ESG:            BASE_URL + "update/gallery",
    NEWS:           BASE_URL + "update/news",
    NOTICE:         BASE_URL + "update/notice",
    SNS:            BASE_URL + "update/sns",
}


export const registerApi: Key = {
    BANNERS:        BASE_URL + "register/banner",
    POPUPS:         BASE_URL + "register/popup",
    INQUIRY:        BASE_URL + "register/qna",
    HISTORIES:      BASE_URL + "register/history",
    PRODUCTS:       BASE_URL + "register/product",
    PATENTS:        BASE_URL + "register/patent",
    CERTIFICATES:   BASE_URL + "register/certificate",
    ESG:            BASE_URL + "register/gallery",
    NEWS:           BASE_URL + "register/news",
    NOTICE:         BASE_URL + "register/notice",
    SNS:            BASE_URL + "register/sns",
}

