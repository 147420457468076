import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {

    deleteProcurment,
    deleteProcurmentGuide,
    deleteProcurmentTableCol,
    deleteProcurmentTableRow,
    procurmentCategoryData, 
    registerAddColumn, 
    registerAddList, 
    registerAddRow,
    updateProcurement,

} from "../../api/Apis";

import { ListType } from "../../interface/Interface";


const Procurement = ( data:ListType ) => {

    const [ category,                   setCategory ]                   = useState([]);
    const [ detailInfo,                 setDetailInfo ]                 = useState<any>();

    const [ guideList,                  setGuideList ]                  = useState([]);
    const [ selKindList,                setKindList]                    = useState<any>([]);
    const [ selOptionList,              setOptionList]                  = useState<any>([]);
    const [ labelExList,                setLabelExList]                 = useState<any>([]);

    const [ table,                      setTable ]                      = useState([]);
    const [ tableHeader,                setTableHeader ]                = useState([]);

    const [ isBlock,                    setIsBlock ]                    = useState();

    const [ selectType,                 setSelectType ]                 = useState(0);

    const [ title,                      setTitle ]                      = useState('');
    const [ tags,                       setTags ]                       = useState('');
    const [ short_content,              setShortContent ]               = useState('');
    const [ deliverGuide,               setDeliverGuide ]               = useState('');
    const [ contractText,               setContractText]                = useState('');
    
    const [ preview,                    setPreview ]                    = useState<string>('');
    const [ previewCenterSearchImage,   setPreviewCenterSearchImage ]   = useState<string>('');
    const [ previewLabelImageFirst,     setPreviewLabelImageFirst ]     = useState<string>('');
    const [ previewLabelImageSecond,    setPreviewLabelImageSecond ]    = useState<string>('');
    const [ previewLabelImageThird,     setPreviewLabelImageThird ]     = useState<string>('');

    const [ file,                       setFile ]                       = useState<File   | null>(null);
    const [ centerImageFile,            setCenterImageFile ]            = useState<File   | null>(null);
    const [ labelImageFirstFile,        setLabelImageFirstFile ]        = useState<File   | null>(null);
    const [ labelImageSecondFile,       setLabelImageSecondFile ]       = useState<File   | null>(null);
    const [ labelImageThirdFile,        setLabelImageThirdFile ]        = useState<File   | null>(null);
    const [ contractFile,               setContractFile ]               = useState<File   | null>(null);
    const [ labelFirstText,             setLabelFirstText ]             = useState('');
    const [ labelSecondText,            setLabelSecondText ]            = useState('');
    const [ labelThirdText,             setLabelThirdText ]             = useState('');

    const [ table_data, setTableData ] = useState<any>([]);

    const [ tableScrollValue, setTableScrollValue ] = useState(0);


    // useRef List
    const inputFile = useRef<HTMLInputElement>(null);
    const inputFileSearchImage = useRef<HTMLInputElement>(null);
    const labelImageFirst = useRef<HTMLInputElement>(null);
    const labelImageSecond = useRef<HTMLInputElement>(null);
    const labelImageThird = useRef<HTMLInputElement>(null);

    const textAreaRef = useRef<null[] | HTMLTextAreaElement[]>([]);
    const tableScrollRef = useRef<HTMLDivElement>(null);
    const leftDeleteUlRef = useRef<HTMLUListElement>(null);


    const categoryList = [
        { key: 0, name: "혁신장터"},
        // { key: 1, name: "벤처나라"},
        { key: 2, name: "학교장터"},
        { key: 3, name: "동반성장물"},
        { key: 4, name: "우수조달"},
    ];

    const deleteButtonHandle = () => {  

        if(window.confirm("정말로 삭제하시겠습니까? 5개 카테고리 데이터 모두 삭제됩니다.")) {
            deleteProcurment(detailInfo.ref_procurementList_id).then(res => {
                if(res.success) {
                    window.location.href = "/main";
                }
            });
        }

    };

    const saveButtonHandle = () => {  



        /* Call save(update) Api */  
        let updateData:any = [];

        updateData['id']                    = detailInfo.id;
        updateData['type']                  = detailInfo.type;
        updateData['ref_id']                = detailInfo.ref_procurementList_id;
        
        updateData['status']                = isBlock;
        updateData['title']                 = title;
        updateData['tags']                  = tags;
        updateData['shortContent']          = short_content;
        updateData['productImageFile']      = file;

        updateData['table']                 = table_data;
        updateData['tableHeader']           = tableHeader;

        updateData['deliverGuide']          = deliverGuide;

        updateData['contractFile']          = contractFile;

        updateData['selKindList']           = selKindList;
        updateData['selOptionList']         = selOptionList;
        updateData['labelExList']           = labelExList;

        updateData['centerImageFile']       = centerImageFile;

        updateData['labelFirstText']        = labelFirstText;
        updateData['labelSecondText']       = labelSecondText;
        updateData['labelThirdText']        = labelThirdText;

        updateData['labelImageFirstFile']   = labelImageFirstFile;
        updateData['labelImageSecondFile']  = labelImageSecondFile;
        updateData['labelImageThirdFile']   = labelImageThirdFile;


        // 리스트 id
        // 리스트 타입

        // 사용자 화면 노출 여부
        // 상품명칭
        // 태그
        // 짧은 소개글
        // 상품이미지 
        // 표 데이터
        // 배송 관련 주의사항
        // 계약 서류

        // 구매방법
        // 구매옵션
        // 홍보용 라벨 부착 예시

        // 라벨 부착 사진 + 텍스트 1, 2, 3

        updateProcurement(updateData).then(res => {

            console.log(res);

            if(res.success) {
                // window.location.href = "/main";
            }
        });

    };

    //
    useEffect(() => { 

        if(!data.id) {
            alert("상품 ID를 확인할 수 없습니다. [Procurment Detail Missing data.id]");
            window.location.href = "/main";
        }
        
        procurmentCategoryOnClickHandle(Number(data.type));
        
    }, []);

    // 호출된 해당 상세 데이터 분기 처리
    const procurmentCategoryOnClickHandle = (type:number) => {
        
        if(!data.ref_procurementList_id) return;

        procurmentCategoryData(data.ref_procurementList_id , String(type)).then((res) => {

            const resData = res.data;

            if(res.success) {

                if(resData) {

                    setPreviewLabelImageFirst('');
                    setPreviewLabelImageSecond('');
                    setPreviewLabelImageThird('');
                    setLabelFirstText('');
                    setLabelSecondText('');
                    setLabelThirdText('');
                    setPreviewCenterSearchImage('');
                    setContractText('');
                    setKindList([]);
                    setOptionList([]);
                    setLabelExList([]);

                    setCategory(resData.category);
                    setDetailInfo(resData.detailInfo);

                    setGuideList(resData.guideList);

                    resData.guideList.map((item:any, index:number) => {

                        if(item.type == 0) {
                            setKindList((selKindList: any) => [...selKindList, item]);

                        } else if(item.type == 1) {

                            setOptionList((selOptionList: any) => [...selOptionList, item]);

                        } else if(item.type == 2) {

                            setLabelExList((labelExList: any) => [...labelExList, item])

                        }
                        
                    });

                    setTable(resData.table);
                    setTableHeader(resData.tableHeader);

                    setSelectType(type);
                    setIsBlock(resData.detailInfo.status);

                    setTitle(resData.detailInfo.title || '');
                    setTags(resData.detailInfo.tags || '');
                    setShortContent(resData.detailInfo.short_content || '');
                    setDeliverGuide(resData.detailInfo.deliverGuide || [])
                    
                    setPreview(resData.detailInfo.fileAddr || '');

                    // if(resData.guideList.length < 1) {
                    //     setLabelFirstText('');
                    //     setPreviewLabelImageFirst('');
                    //     setLabelSecondText('');
                    //     setPreviewLabelImageSecond('');
                    //     setLabelThirdText('');
                    //     setPreviewLabelImageThird('');
                    //     setPreviewCenterSearchImage('');
                    //     setContractText('');
                    //     setKindList([]);
                    //     setOptionList([]);
                    //     setLabelExList([]);
                    // }

                    resData.guideList.map((item:any, index:number) => {

                        // 사진1
                        if(item.type == 3) {
                            
                            setLabelFirstText(item.content);
                            setPreviewLabelImageFirst(item.fileAddr);

                        } else if(item.type == 4) {

                            setLabelSecondText(item.content);
                            setPreviewLabelImageSecond(item.fileAddr);
                            
                        } else if(item.type == 5) {

                            setLabelThirdText(item.content);
                            setPreviewLabelImageThird(item.fileAddr);
                            
                        } else if(item.type == 6) {
                            
                            setPreviewCenterSearchImage(item.fileAddr);
                            
                        } else if(item.type == 7) {

                            setContractText(item.fileAddr);

                        }

                    });        

                    let tempNumber= 0;

                    if(resData.table[resData.table.length-1]) {
                        if(resData.table[resData.table.length-1].row) {
                            tempNumber = resData.table[resData.table.length-1].row;
                        }
                    }

                    tableRenderData(resData.table, tempNumber);

                }
            }
        })

    }

    // 사용자 화면 내 해당 카테고리 노출 여부를 결정
    const isBlockOnChangeHandle = (e:any) => {
        // 사용자 화면 노출 여부 설정
        setIsBlock(e.target.value);
    }

    const titleOnChangeHandle = (e:any) => { setTitle(e.target.value); }
    const tagsOnChangeHandle = (e:any) => { setTags(e.target.value); }
    const shortContentOnChangeHandle = (e:any) => { setShortContent(e.target.value); }
    
    // handle Ref input file click
    const fileOnClickFileChangeHandle = (type:number) => { 

        switch (type) {

            case 0:

                if(inputFile.current) inputFile.current.click();
                break;

            case 3:

                if(labelImageFirst.current) labelImageFirst.current.click();
                break;

            case 4:

                if(labelImageSecond.current) labelImageSecond.current.click();
                break;

            case 5:

                if(labelImageThird.current) labelImageThird.current.click();
                break;

            case 6:

                if(inputFileSearchImage.current) inputFileSearchImage.current.click();
                break; 
    
            default:
                break;
        }
        
    };

    // handle file change
    const fileOnChangeHandle = (type:number) => {

        if(inputFile.current?.files) {

            let reader = new FileReader();
            let file = inputFile.current.files[0];
            
            if(file){

                setFile(file);
                reader.onload = () => { setPreview(URL.createObjectURL(file)); };
                reader.readAsDataURL(file);

            }
        }

        if(inputFileSearchImage.current?.files) {

            let reader = new FileReader();
            let file = inputFileSearchImage.current.files[0];
            
            if(file){

                setCenterImageFile(file);
                reader.onload = () => { setPreviewCenterSearchImage(URL.createObjectURL(file)); };
                reader.readAsDataURL(file);

            }
        }

        if(labelImageFirst.current?.files) {

            let reader = new FileReader();
            let file = labelImageFirst.current.files[0];
            
            if(file){

                setLabelImageFirstFile(file);
                reader.onload = () => { setPreviewLabelImageFirst(URL.createObjectURL(file)); };
                reader.readAsDataURL(file);

            }
        }

        if(labelImageSecond.current?.files) {

            let reader = new FileReader();
            let file = labelImageSecond.current.files[0];
            
            if(file){

                setLabelImageSecondFile(file);
                reader.onload = () => { setPreviewLabelImageSecond(URL.createObjectURL(file)); };
                reader.readAsDataURL(file);

            }
        }

        if(labelImageThird.current?.files) {

            let reader = new FileReader();
            let file = labelImageThird.current.files[0];
            
            if(file){

                setLabelImageThirdFile(file);
                reader.onload = () => { setPreviewLabelImageThird(URL.createObjectURL(file)); };
                reader.readAsDataURL(file);

            }
        }

    }
    
    // 테이블 헤더 컬럼 데이터 변경 시, useState Array 수정
    const headerStringOnChange = (e:any, columnNumber:number) => {
        
        let tableHeaderTemp:any = [];

        tableHeader.map((item:any, index:number) => {

            let editColumn = "";

            if(index == columnNumber) {

                editColumn = e.target.value;

            }

            tableHeaderTemp.push({
                id: item.id,
                ref_procurement_id: item.ref_procurement_id,
                content: editColumn ? editColumn : item.content,
            })


        })

        setTableHeader(tableHeaderTemp);
        
    }
    
    // 테이블 데이터 초기화
    const tableRenderData = (table:any, lastNumber:number) => {

        let row:any = [];
        let col:any = [];

        for (let rowLine = 1; rowLine <= lastNumber; rowLine++) {

            table.map((item:any, index:number) => {

                if(rowLine == Number(item.row)) {

                    col.push({

                        id: item.id,
                        row: item.row,
                        type: item.type,
                        ref_table_header_id: item.ref_table_header_id,
                        content: item.content,  

                    })

                }
                
            });

            if(col.length > 0) {
                row.push(col);
            }
            
            col = [];
        }
        
        setTableData(row);
    }

    //
    const inputTypeOnChangeHandle = (e:any, ele:any) => {

        let row:any = [];
        let col:any = [];


        table_data.map((item:any, i:number) => {
            
            item.map((column:any, j:number) => {

                let editColumn = false;

                if( ele.ref_table_header_id == column.ref_table_header_id 

                    &&  column.row == ele.row

                ) {

                    editColumn = e.target.checked;

                } else {

                    editColumn = column.type;

                }

                col.push({
                    id: column.id,
                    row: column.row,
                    type: editColumn,
                    ref_table_header_id: column.ref_table_header_id,
                    content: column.content,  
                })

            })

            row.push(col);
            col = [];

        })

        setTableData(row);

    }

    // 테이블 내 컬럼 데이터 변경 시, useState Array 수정
    const columnEditOnChageHandle = (e:any, ele:any) => {

        let row:any = [];
        let col:any = [];

        table_data.map((item:any, i:number) => {
            
            item.map((column:any, j:number) => {

                let editColumn = "";

                if( ele.ref_table_header_id == column.ref_table_header_id 
                    &&  column.row == ele.row
                ) {

                    editColumn = e.target.value;
                }

                col.push({

                    id: column.id,
                    row: column.row,
                    type: column.type,
                    ref_table_header_id: column.ref_table_header_id,
                    content: editColumn ? editColumn : column.content,  

                })

            })

            row.push(col);
            col = [];

        })

        setTableData(row);

    }

    // row delete button handle [ server ]
    const deleteRowOnClickHandle = (e:any, deleteRowNumber:number) => {

        if(window.confirm("정말로 행을 삭제하시겠습니까? 데이터가 삭제됩니다.")) {
        
            // api delete table row
            deleteProcurmentTableRow(deleteRowNumber).then((res) => {

                if(res.success) {
                    
                    // if success active

                    let tableDataTempArray:any = [];

                    table_data.map((item:any, index:number) => {

                        if(item[0].row != deleteRowNumber) {
                            tableDataTempArray.push(item);
                        }

                    });


                    setTableData(tableDataTempArray);
                }

            });

        }

    }

    // col delete button handle [ server ]
    const deleteColumOnClickHandle = (e:any, ele:any) => {

        if(window.confirm("정말로 열을 삭제하시겠습니까? 데이터가 삭제됩니다.")) {

            deleteProcurmentTableCol(ele.id).then((res) => {
                
                // server col delete is Success?
                let headerTempArray:any = [];
                tableHeader.map((item:any, index:number) => {

                    if(item.id != ele.id) {{
                        headerTempArray.push(item);
                    }} // if end

                }); // map end

                setTableHeader(headerTempArray);

                let row:any = [];
                let col:any = [];

                table_data.map((item:any, i:number) => {
                    
                    item.map((column:any, j:number) => {
                        
                        if(ele.id != column.ref_table_header_id) {

                            col.push({
                                row: column.row,
                                type: column.type,
                                ref_table_header_id: column.ref_table_header_id,
                                content: column.content,  
                            })

                        } // if end

                    }) // map end

                    row.push(col);
                    col = [];

                }); // map end
                setTableData(row);  

            }); 
        }

    }

    //
    const addRowOnCickHandle = (e:any) => {

        // server api
        if(tableHeader.length < 1) { alert('열을 먼저 추가해주세요.'); return; }

        registerAddRow(detailInfo.id, detailInfo.type).then((res) => {

            if(res.success) {  
                
                let tableDataTempArray:any = [];

                let col:any = [];
                let row:any = [];

                table_data.map((item:any) => {

                    item.map((ele:any) => {
                        col.push(item);
                    })

                    row.push(col);
                    col = [];
                    
                });

                tableHeader.map((item:any, index:number) => {

                        tableDataTempArray.push({
                            id: res.data.addColumnId[index].id,
                            row: res.data.row,
                            type: 0,                
                            ref_table_header_id: item.id,
                            content: null,
                        })

                })  
                
                setTableData([ ...table_data, tableDataTempArray ]);

            }
        });
        
    }

    //
    const addColOnCickHandle = (e:any) => {

        // server add col

        registerAddColumn(detailInfo.id, detailInfo.type).then((res) => {

            if(res.success) {

                let headerTempArray:any = [];

                tableHeader.map((item:any, index:number) => {
                    headerTempArray.push(item);
                });
        
                headerTempArray.push({id: res.data.headerId, ref_procurement_id: detailInfo.id, content: ''});
                
                let rowTempArray:any = [];
                let colTempArray:any = [];
        
                table_data.map((item:any, index:number) => {
                    
                    item.map((ele:any, j:number) => {
        
                        colTempArray.push({
                            id: ele.id,
                            row: ele.row,
                            type: ele.type,
                            ref_table_header_id: ele.ref_table_header_id,
                            content: ele.content,
                        })
        
                    })
                    
                    console.log(res);

                    colTempArray.push({
                        id: res.data.addColumnId[index].id,
                        row: index+1,
                        type: 0,
                        ref_table_header_id: res.data.headerId,
                        content: '',
                    })
        
                    rowTempArray.push(colTempArray);
                    colTempArray = [];
                    
                });
                
                setTableHeader(headerTempArray);
                setTableData(rowTempArray);
        
            }
        })

        
    }
    
    //
    const scrollTest = (e:any) => {
        let scrollValue = e.target.scrollTop;
        setTableScrollValue(scrollValue)
        if(tableScrollValue >= 0) {
            leftDeleteUlRef.current?.scrollTo(tableScrollValue, scrollValue);
        }
    }

    // 
    const deliverGuideOnChangeHandle = (e:any) => { setDeliverGuide(e.target.value); };

    const labelFirstTextOnChangeHandle = (e:any)  => { setLabelFirstText(e.target.value); };
    const labelSecondTextOnChangeHandle = (e:any) => { setLabelSecondText(e.target.value); };
    const labelThirdTextOnChangeHandle = (e:any)  => { setLabelThirdText(e.target.value); };

    // 계약서류 업로드 핸들
    const contractFileOnChange = (e:any) => {
        
        if(e.target.files.length > 0) {
            setContractFile(e.target.files[0]);   
        }
    }

    // guide list input edit function
    const labelExEditOnChange = (e:any, item:any) => {

        let tempArray:any = [];

        if(item.type == 0) {

            selKindList.map((ele:any, index:number) => {
                if(ele.id == item.id) {
                    tempArray.push(
                        {
                            id: ele.id,
                            type: ele.type,
                            content: e.target.value,
                        }
                    )
                } else {
                    tempArray.push(ele);
                }
            });

            setKindList(tempArray);

        } else if(item.type == 1) {

            selOptionList.map((ele:any, index:number) => {
                if(ele.id == item.id) {
                    tempArray.push(
                        {
                            id: ele.id,
                            type: ele.type,
                            content: e.target.value,
                        }
                    )
                } else {
                    tempArray.push(ele);
                }
            });

            setOptionList(tempArray);

        } else if(item.type == 2) {

            labelExList.map((ele:any, index:number) => {
                if(ele.id == item.id) {
                    tempArray.push(
                        {
                            id: ele.id,
                            type: ele.type,
                            content: e.target.value,
                        }
                    )
                } else {
                    tempArray.push(ele);
                }
            });

            setLabelExList(tempArray);

        }
    }

    // 구매방법 추가 버튼 클릭 핸들
    const selKindAddOnClickHandle = (e:any) => {

        registerAddList(detailInfo.ref_procurementList_id, detailInfo.type , 0).then((res) => {
            let selKindTempArray:any = [];

            selKindList.map((item:any, index:number) => {
                selKindTempArray.push(item);
            });

            selKindTempArray.push({
                id: res.data.id,
                type: 0,
                content: '',
            });

            setKindList(selKindTempArray);
        });

    }

    // 구매옵션 추가 버튼 클릭 핸들
    const selOptionAddOnClickHandle = (e:any) => {

        registerAddList(detailInfo.ref_procurementList_id, detailInfo.type , 1).then((res) => {
            let selOptionTempArray:any = [];

            selOptionList.map((item:any, index:number) => {
                selOptionTempArray.push(item);
            });

            selOptionTempArray.push({
                id: res.data.id,
                type: 1,
                content: '',
            });

            setOptionList(selOptionTempArray);
        });
    }

    // 홍보용 라벨 부착 예시 클릭 핸들
    const labelAddOnClickHandle = (e:any) => {

        registerAddList(detailInfo.ref_procurementList_id, detailInfo.type , 2).then((res) => {

            let labelTempArray:any = [];

            labelExList.map((item:any, index:number) => {
                labelTempArray.push(item);
            });

            labelTempArray.push({
                id: res.data.id,
                type: 2,
                content: '',
            });

            setLabelExList(labelTempArray);
        });

    }

    // guide list delete click function
    const guideListDeleteOnClickHandle = (e:any, item:any) => {

        deleteProcurmentGuide(item.id, item.type).then((res) => {

            let tempArray:any = [];

            if(res.success) {

                switch (item.type) {

                    case 0:

                        selKindList.map((ele:any, index:number) => {
                            if(Number(item.id) != Number(ele.id)) {
                                tempArray.push(ele);
                            }
                        });

                        setKindList(tempArray);

                        break;

                    case 1:

                        selOptionList.map((ele:any, index:number) => {
                            if(Number(item.id) != Number(ele.id)) {
                                tempArray.push(ele);
                            }
                        });

                        setOptionList(tempArray);

                        break;

                    case 2:

                        labelExList.map((ele:any, index:number) => {
                            if(Number(item.id) != Number(ele.id)) {
                                tempArray.push(ele);
                            }
                        });

                        setLabelExList(tempArray);

                        break;
                
                    default:
                        break;
                }

            }

        });

    }

    const contractFileDeleteOnClickHandle = (e:any) => { setContractText(''); }

    const contractFileDownloadOnClickHandle = (e:any, downloadUrl:string) => {

        fetch(downloadUrl)
            .then(response => response.blob())
            .then(blob => {
                if(navigator.userAgent.match(/inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i)){
                    document.body.innerHTML = '';
                    if(navigator.userAgent.match(/iPhone|iPad/i)){
                        if (navigator.userAgent.match(/NAVER/i)) {
                            var icox = window.open(downloadUrl, '', "width=0, height=0, left=0, top=0,  scrollbars=no,status=no,toolbar=no,menubar=no,resizeable=no,location=no");
                            icox!.addEventListener('beforeunload', (event) => {
                                event.preventDefault();
                                event.returnValue = '';
                            });
                            window.location.reload();
                            return; 
                        }
                        var icox = window.open(downloadUrl, '', "width=0, height=0, left=0, top=0,  scrollbars=no,status=no,toolbar=no,menubar=no,resizeable=no,location=no");
                        setTimeout(function () { icox!.close(); window.location.reload(); }, 100);
                    }
                }
                let blobUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = downloadUrl.replace(/^.*[\\\/]/, '');
                a.href = blobUrl;
                document.body.appendChild(a);
                a.click();
                a.remove();
        })

    }

    return (
        <Container>

            {/* Top Options & Info Section */}
            <Options>

                {/* Delete Button */}
                <DeleteButton
                    onClick={deleteButtonHandle}
                >
                    삭제
                </DeleteButton>

                <SaveButton
                    onClick={saveButtonHandle}
                >
                    저장
                </SaveButton>

                <h4></h4>
            </Options>

            <DataSection>

                {/* Type List */}
                <Row height={40}>

                    {
                        categoryList.map((item:any, index:number)=> {
                            // procurmentCategoryButton
                            // procurmentCategoryButton selectedCategory
                            return (
                                <Col width={20} key={index}>
                                    <button 
                                        className={ item.key == Number(detailInfo ? detailInfo.type : "") ? "selectedCategory" : ""}
                                        onClick={(e) => procurmentCategoryOnClickHandle(item.key)}
                                    >
                                        {item.name}
                                    </button>
                                </Col>
                            )
                        })
                    }
                    
                </Row>

                {/* Data Id */}
                <Row height={50}>
                    <Col width={20}>고유번호</Col>
                    <Col width={80}>{ detailInfo?.id || data.id }</Col>
                </Row>

                {/* created_at */}
                <Row height={40}>
                    <Col width={20}>작성일</Col>
                    <Col width={80}>{ data.created_at }</Col>
                </Row>

                {/* Is Block Select */}
                <Row height={50}>
                    <Col width={20}>사용자 화면 <br/> 노출여부</Col>
                    <Col width={80}>

                        <select 
                            onChange={(e) => isBlockOnChangeHandle(e)} 
                            value={isBlock}
                        >
                            <option value="0">노출</option>
                            <option value="1">비노출</option>
                        </select>
                        
                    </Col>
                </Row>

                {/* 상품명칭 */}
                <Row height={40}>
                    <Col width={20}>상품명칭</Col>
                    <Col width={80}>
                       <input type="text" value={title || ''} onChange={(e) => titleOnChangeHandle(e)}/>
                    </Col>
                </Row>

                {/* 태그 */}
                <Row height={40}>
                    <Col width={20}>태그</Col>
                    <Col width={80}>
                        <input type="text" value={tags || ''} onChange={(e) => tagsOnChangeHandle(e)}/>
                    </Col>
                </Row>

                {/* 짧은소개글 */}
                <Row height={40}>
                    <Col width={20}>짧은소개글</Col>
                    <Col width={80}>
                        <input type="text" value={short_content || ''} onChange={(e) => shortContentOnChangeHandle(e)}/>
                    </Col>
                </Row> 

                {/* 상품이미지 */}
                <Row height={400}>
                    <Col width={20}>
                        상품이미지 <br/> 
                        (이미지을 통해 사진을 변경할 수 있습니다.) <br/>
                        * 상단 저장 버튼을 눌러주셔야 사진이 변경됩니다.
                    </Col>
                    <Col width={80}>
                        
                        { 
                            preview ? 

                                <img onClick={(e) => fileOnClickFileChangeHandle(0)} src={preview}></img>

                                :

                                <button className="productImageAddBtn" onClick={(e) => fileOnClickFileChangeHandle(0)}>사진 추가하기</button>
                        }
                        
                    </Col>
                </Row>

                {/* 테이블 */}
                <Row height={300}>

                    <Col width={20}>
                        <div className="container">
                            <span>테이블작성</span> 
                            <div className="controllerContainer">
                                <button 
                                    className="rowAddButton"
                                    onClick={(e) => addRowOnCickHandle(e)}
                                >
                                    행추가
                                </button>
                                <button 
                                    className="columnAddButton"
                                    onClick={(e) => addColOnCickHandle(e)}
                                >
                                    열추가
                                </button>
                            </div>
                        </div>
                    </Col>

                    <Col width={80}>
                        <div className="tableContainer">

                            <div className="tableData">

                                <div className="tableControllerLeftSection">

                                    <div className="rowDeleteEmptySection" />
                            
                                    {/* left Row Delete Button Section */}
                                    <ul 
                                        className="rowDeleteContainer"
                                        ref={leftDeleteUlRef}
                                    >

                                        {   

                                            table_data.map((item:any, index:number) => {

                                                if(item.length > 0) {
                                                    
                                                    return (
                                                        <li 
                                                            className="rowDeleteBox"
                                                            key={index}
                                                        >
                                                            <button 
                                                                className="rowDelete"
                                                                onClick={(e) => deleteRowOnClickHandle(e, item[0].row)}
                                                            >
                                                                <span>DEL:{index}</span>
                                                            </button>
                                                        </li>
                                                    )

                                                }
                                            })

                                        }

                                    </ul>

                                </div>

                                <div className="tableTopAndDataContainer">

                                    {/* Column Delete Section */}
                                    <div className="tableControllerTopSection">

                                        {
                                            tableHeader.map((item:any, index:number) => {

                                                return (
                                                    <div 
                                                        className="deleteBox"
                                                        key={index}
                                                    >
                                                        <button 
                                                            className="columnDelete"
                                                            onClick={(e) => deleteColumOnClickHandle(e, item)}
                                                        >
                                                            <span>DEL:{index}</span>
                                                        </button>
                                                    </div>
                                                )

                                            })
                                        }                                        

                                    </div>

                                    <div className="data">

                                        {/* Column Header Section */}
                                        <ul className="dataHeaderSection">

                                            {
                                                tableHeader.map((item:any, index:number) => {
                                                    return (
                                                        <li 
                                                            className="dataHeaderColumn"
                                                            key={index}
                                                        >
                                                            <textarea 
                                                                ref={el => textAreaRef.current[index] = el}
                                                                onChange={(e) => headerStringOnChange(e, index)}
                                                                value={item.content || ''} 
                                                            />
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>

                                        {/* Row(column) Data Section */}
                                        <div 
                                            className="dataSection"
                                            ref={tableScrollRef}
                                            onScroll={(e) => scrollTest(e)}
                                        >
                                            
                                            {
                                                table_data.map((item:any, i:number) => {
                                                    return (
                                                        <ul 
                                                            className="dataRow"
                                                            key={i}
                                                        >
                                                                {
                                                                item.map((ele:any, j:number) => {
                                                                    return ( 
                                                                        <li 
                                                                            className="dataColumn" 
                                                                            key={j}
                                                                        >
                                                                            <input 
                                                                                type="checkbox" 
                                                                                className="inputTyeClass"
                                                                                checked={ele.type == 1 ? true : false}
                                                                                onChange={(e) => inputTypeOnChangeHandle(e, ele)}
                                                                            />
                                                                            <input 
                                                                                type="text" 
                                                                                value={ele.content || ''} 
                                                                                onChange={(e) => columnEditOnChageHandle(e, ele)}
                                                                            />
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row> 

                {/* 표 주의사항 */}
                <Row height={40}>
                    <Col width={20}>주의사항</Col>
                    <Col width={80}>
                        <input type="text" value={deliverGuide} onChange={(e) => deliverGuideOnChangeHandle(e)}/>
                    </Col>
                </Row>

                {/* 검색방법이미지 */}
                <Row height={400}>
                    <Col width={20}>검색방법이미지</Col>
                    <Col width={80}>
                        { previewCenterSearchImage ? 
                                <img onClick={(e) => fileOnClickFileChangeHandle(6)} src={ previewCenterSearchImage || ''}></img>
                                :
                                <button className="productImageAddBtn" onClick={(e) => fileOnClickFileChangeHandle(6)}>사진 추가하기</button>
                        }
                    </Col>
                </Row>

                {/* 구매방법 */}
                <Row height={200}>
                    <Col width={20}>

                        구매방법

                        <button 
                            className="guideListAdd"
                            onClick={(e) => selKindAddOnClickHandle(e)}
                        >
                            추가하기
                        </button>

                    </Col>
                    <Col width={80}>
                        <div className="labelListContainer">
                            { 
                                selKindList.map((item:any, index:number) => {

                                    return (

                                        <div
                                            key={index} 
                                            className="labelList"
                                        >

                                            <input 
                                                className="labelInput"
                                                type="text" 
                                                onChange={(e)=>labelExEditOnChange(e, item)}
                                                value={item.content}
                                            />

                                            <button 
                                                className="labelDelete"
                                                onClick={(e) => guideListDeleteOnClickHandle(e, item)}
                                            > 
                                                삭제
                                            </button>

                                        </div>
                                    )

                                })
                            }
                            
                        </div>
                    </Col>
                </Row>

                {/* 계약서류 */}
                <Row height={50}>
                    <Col width={20}>계약서류</Col>
                    <Col width={80}>

                        <div className="contractContainer">
                        
                            {
                                contractText ?
                                <div> 
                                    <button onClick={(e) => contractFileDownloadOnClickHandle(e, contractText)}>
                                        다운로드
                                    </button>

                                    <span onClick={(e) => contractFileDeleteOnClickHandle(e)}>X</span>
                                </div>

                                :

                                <input type="file" onChange={(e) => contractFileOnChange(e)}/>
                            }

                        </div>
                        
                    </Col>
                </Row>

                {/* 구매옵션 */}
                <Row height={200}>
                    <Col width={20}>
                        구매옵션
                        <button 
                            className="guideListAdd"
                            onClick={(e) => selOptionAddOnClickHandle(e)}
                        >
                            추가하기
                        </button>
                    </Col>
                    <Col width={80}>
                        <div className="labelListContainer">
                            { 
                                selOptionList.map((item:any, index:number) => {

                                    return (

                                        <div
                                            key={index} 
                                            className="labelList"
                                        >

                                            <input 
                                                className="labelInput"
                                                type="text" 
                                                onChange={(e)=>labelExEditOnChange(e, item)}
                                                value={item.content}
                                            />

                                            <button 
                                                className="labelDelete"
                                                onClick={(e) => guideListDeleteOnClickHandle(e, item)}
                                            > 
                                                삭제
                                            </button>

                                        </div>
                                    )

                                })
                            }
                            
                        </div>
                    </Col>
                </Row>

                {/* 홍보용 라벨 부착 예시 */}
                <Row height={200}>
                    <Col width={20}>
                        홍보용 라벨 부착 예시
                        <button 
                            className="guideListAdd"
                            onClick={(e) => labelAddOnClickHandle(e)}
                        >
                            추가하기
                        </button>
                    </Col>
                    <Col width={80}>
                        <div className="labelListContainer">
                            { 
                                labelExList.map((item:any, index:number) => {

                                    return (

                                        <div
                                            key={index} 
                                            className="labelList"
                                        >

                                            <input 
                                                className="labelInput"
                                                type="text" 
                                                onChange={(e)=>labelExEditOnChange(e, item)}
                                                value={item.content}
                                            />

                                            <button 
                                                className="labelDelete"
                                                onClick={(e) => guideListDeleteOnClickHandle(e, item)}
                                            > 
                                                삭제
                                            </button>

                                        </div>
                                    )

                                })
                            }
                            
                        </div>
                    </Col>
                </Row>

                {/* 홍보용 라벨 부착 사진 예시 */}
                <Row height={400}>

                    <Col width={20}>라벨 부착 예시</Col>

                    <Col width={26.6}>
                        <input 
                            className="labelInput" 
                            type="text" 
                            placeholder="부착 예시 제목을 입력하세요"
                            onChange={(e)=>labelFirstTextOnChangeHandle(e)}
                            value={labelFirstText || ''}
                        />
                        { previewLabelImageFirst ? 
                                <img onClick={(e) => fileOnClickFileChangeHandle(3)} src={ previewLabelImageFirst || ''}></img>
                                :
                                <button className="productImageAddBtn" onClick={(e) => fileOnClickFileChangeHandle(3)}>사진 추가하기</button>
                        }
                    </Col>

                    <Col width={26.6}>
                        <input 
                            className="labelInput" 
                            type="text" 
                            placeholder="부착 예시 제목을 입력하세요"
                            onChange={(e)=>labelSecondTextOnChangeHandle(e)}
                            value={labelSecondText || ''}
                        />
                        { previewLabelImageSecond ? 
                                <img onClick={(e) => fileOnClickFileChangeHandle(4)} src={ previewLabelImageSecond || ''}></img>
                                :
                                <button className="productImageAddBtn" onClick={(e) => fileOnClickFileChangeHandle(4)}>사진 추가하기</button>
                        }
                    </Col>

                    <Col width={26.6}>
                        <input 
                            className="labelInput" 
                            type="text" 
                            placeholder="부착 예시 제목을 입력하세요"
                            onChange={(e)=>labelThirdTextOnChangeHandle(e)}
                            value={labelThirdText || ''}
                        />   
                        { previewLabelImageThird ? 
                                <img onClick={(e) => fileOnClickFileChangeHandle(5)} src={ previewLabelImageThird || ''}></img>
                                :
                                <button className="productImageAddBtn" onClick={(e) => fileOnClickFileChangeHandle(5)}>사진 추가하기</button>
                        }
                    </Col>

                </Row>

                {/* 상품 대표 이미지 설정 */}
                <input 
                    type="file" 
                    ref={inputFile} 
                    onChange={(e) => fileOnChangeHandle(0)}
                    style={{display: 'none'}} 
                    accept="image/*" 
                />

                {/* 검색 방법 이미지 설정 */}
                <input 
                    type="file" 
                    ref={inputFileSearchImage} 
                    onChange={(e) => fileOnChangeHandle(6)}
                    style={{display: 'none'}} 
                    accept="image/*" 
                />

                {/* 라벨부착 예시 1 */}
                <input 
                    type="file" 
                    ref={labelImageFirst} 
                    onChange={(e) => fileOnChangeHandle(3)}
                    style={{display: 'none'}} 
                    accept="image/*" 
                />

                {/* 라벨부착 예시 2 */}
                <input 
                    type="file" 
                    ref={labelImageSecond} 
                    onChange={(e) => fileOnChangeHandle(4)}
                    style={{display: 'none'}} 
                    accept="image/*" 
                />

                {/* 라벨부착 예시 3 */}
                <input 
                    type="file" 
                    ref={labelImageThird} 
                    onChange={(e) => fileOnChangeHandle(5)}
                    style={{display: 'none'}} 
                    accept="image/*" 
                />

            </DataSection>
        </Container>
    )
}

export default Procurement;

//
const Container = styled.div`

    width: 100%;
    min-width: 500px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

`;

//
const DataSection = styled.div`

    width: 98%;
    height: calc(100% - 40px);
    margin-top: 50px;
    overflow-y: auto;
    padding-top: 40px;
`;

//
const Row = styled.div<{ height: number }>`

    width: 100%;
    min-height: 40px;

    height: ${props => (props.height ? props.height+"px;" : "40px;" )}

    display: flex;
    flex-direction: row;
    
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 1rem;

`;

//
const Col = styled.div<{ width: number }>`

    width: ${props => (props.width ? props.width+"%;" : "10%;" )}

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: thin solid #ffffff6e;

    text-align: center;

    overflow-y: auto;

    button {

        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        color: white;
        font-weight: bold;
        font-size: 1rem;

        &:hover {
            cursor: pointer;
            background-color: #5b6f9d;
        }

    }

    .contractContainer {

        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
            width: 100px;
            height: 30px;
            border-radius: 5px;
            background-color: blue;
            margin-right: 10px;
            font-weight: bold;
        }

        span {
            &:hover {
                cursor: pointer;
            }
        }

        input { 
            width: 300px;
            height: 20px;
            background-color: #202125;
        }
    }

    .guideListAdd {
        width: 80px;
        height: 40px;
        background-color: #696995;
        border-radius: 5px;
        margin-top: 10px;
    }

    .selectedCategory {
        background-color: #5b6f9d; 
    }

    .productImageAddBtn {
        width: 170px;
        height: 60px;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: bold;
        background-color: white;
        color: black;

        
    }

    img {

        width: 320px;
        border-radius: 2px;

    }

    input {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    span {

        margin-bottom: 20px;

    }

    select {

        width: 100%;
        height: 100%;
        border: none;
        text-align: center;
        background-color: #7c4c4c78;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        appearance: none;

    }

    .container {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .controllerContainer {

            width: 120px;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .rowAddButton {
                width: 60%;
                height: 30px;
                background-color: #696995;
                border-radius: 5px;
                margin-bottom: 10px;
            }

            .columnAddButton {
                width: 60%;
                height: 30px;
                background-color: #696995;
                border-radius: 5px;
            }
        }
    }

    .labelInput {
        width: 100%;
        height: 40px;
        position: absolute;
        top: 0;
    }

    .tableContainer {

        width: 100%;
        height: 100%;
        
        display: flex;
        flex-direction: row;

        }

        .tableData {

            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: row;

            .tableControllerLeftSection {
                
                width: 100px;
                height: 100%;

                .rowDeleteEmptySection {
                    width: 100%;
                    height: 90px;
                }

                .rowDeleteContainer {

                    width: 100%;
                    min-height: calc(100% - 90px);
                    max-height: calc(100% - 90px);
                    list-style-type : none;

                    display:flex;
                    flex-direction: column;

                    overflow-y: auto;
                    margin: 0px;
                    
                    .rowDeleteBox {

                        width: 100%;
                        height: 40px;
                        min-height: 40px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        .rowDelete {
                            width: 50px;
                            height: 25px;
                            border-radius: 5px;
                            background-color: red;
                            font-size: 0.7rem;
                        }
                    }

                }

            }

            .tableTopAndDataContainer {

                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;

                overflow: auto;

                .tableControllerTopSection {

                    width: 100%;
                    height: 50px;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: sticky;
                    top: 0;

                    .deleteBox {

                        width: 200px;
                        min-width: 200px;
                        
                        height: 100%;
                        display: flex;

                        align-items: center;
                        justify-content: center;
                    
                        .columnDelete {

                            width: 60px;
                            height: 25px;
                            border-radius: 5px;
                            margin: auto;

                            background-color: red;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            span {
                                font-size: 0.7rem;
                                margin: 0;
                            }

                        }
                    }
                    

                }

                .data {

                    width: 100%;
                    height: calc(100% - 50px);

                    .dataHeaderSection {
                         
                        width: max-content;
                        height: 40px;

                        display: flex;
                        flex-direction: row;

                        // border: 1px solid white; 
                    
                        .dataHeaderColumn {

                            min-width: 200px;
                            max-width: 200px;
                            height: 100%;

                            display : flex;
                            align-items: center;
                            justify-content: center;                            
                            color: black;

                            textarea {

                                width: 100%;
                                height: 100%;
                                background-color: black;
                                border:none;
                                margin: 0;
                                color: white;
                                padding-top: 10.5px;
                                font-size: 15px;
                                font-weight: bold;
                                resize: none;
                                text-align: center;
                                background-color: gray;
                                box-sizing: border-box;
                                white-space: break-spaces;
                                padding-left: 10px;
                                outline: none;

                            }

                        }
                    }

                    .dataSection {

                        width: min-content;
                        max-height: calc(100% - 40px);
                        overflow-y: auto;
                        
                        .dataRow {

                            width: 100%;
                            height: 40px;

                            display: flex;
                            flex-direction: row;
                            background-color: transparent;

                            .inputTyeClass {
                                width: 20px;
                                hieght: 100%;
                            }

                            .dataColumn {

                                width: 200px;
                                min-width: 200px;
                                height: 100%;

                                display: flex;
                                align-items: center;
                                justify-content: center;

                                padding-left: 10px;
                                box-sizing: border-box;

                                border-bottom: 1px solid white;

                                input {
                                    background-color: transparent;
                                    outline: none;
                                }

                                span {
                                    color: black;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .labelListContainer {

        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .labelList {

            display: flex;
            width: 100%;
            height: 40px;
            margin-bottom: 5px;

            .labelInput {
                width: 90%;
                height: 40px;
                position: unset;
            }

            .labelDelete {
                width: 10%;
                height: 40px;
                background-color: red;
                color: white;
            }
        }
    }
    
`;

// 
const Options = styled.div`

    width: 100%;
    height: 40px;
    min-height: 40px;
    position: fixed;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;

    h4 {
        margin-left: 20px;
        color: red;
    }
`;

//
const DeleteButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #43424c;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;

//
const SaveButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #4c486e;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;