import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { deleteBanner, updateBanner, useDeleteApi } from "../../api/Apis";
import { ListType } from "../../interface/Interface";
import { detailOpen, language } from "../../state/store";


const Banner = (data:ListType) => {

    const [ title,    setTitle ]    = useState<string | null>(data.title);
    const [ content,  setContent]   = useState<string | null>(data.content);
    const [ file,     setFile ]     = useState<File   | null>(null);
    const [ preview,  setPreview ]  = useState<string | null>(data.fileAddr);
    const [ type,     setType ]     = useState<number>(Number(data.type));
    const [ isDetail, setIsDetail ] = useRecoilState(detailOpen);

    const [ id, setId ] = useState<string>(data.id);
    const [ file_id, setFileId ] = useState<string>(data.file_id);

    const inputFile = useRef<HTMLInputElement>(null);
    const videoRef  = useRef<HTMLVideoElement>(null);

    //  handle function
    const titleHandle = (e:React.ChangeEvent<HTMLTextAreaElement>) => { 
        let enterCheck = e.target.value.split("\n");
        if(enterCheck.length < 3) {
            setTitle(e.target.value);
        }
    };

    const contentHandle = (e:React.ChangeEvent<HTMLTextAreaElement>) => { 
        let enterCheck = e.target.value.split("\n");
        if(enterCheck.length < 3) {
            setContent(e.target.value); 
        }
    };

    const deleteButtonHandle = () => { 

        if(window.confirm("정말로 삭제하시겠습니까?")) {
            
            setId(data.id);
            setFileId(data.file_id);

            deleteBanner(data.id, data.file_id).then((res) => {
                if(res.success) {
                    window.location.href = "/main";
                }
            })
        }
        
    }

    const saveButtonHandle = () => { 

        /* Call save(update) Api */ 
        // 타이틀
        // 소제목
        // 첨부파일
        
        let updateData:any = [];

        updateData['id']              = data.id;
        updateData['title']           = title;
        updateData['content']         = content;
        updateData['ext']             = type;
        updateData['file']            = file;

        updateBanner(updateData).then(res => {
            console.log('testz');
            if(res.success) {
                window.location.href = "/main";
            }
        });
        
    };

    // handle Ref input file click
    const fileOnClickFileChangeHandle   = () => { if(inputFile.current) { inputFile.current.click(); } }

    // handle file change
    const fileOnChangeHandle            = () => {

        if(inputFile.current?.files) {

            let reader = new FileReader();
            let file = inputFile.current.files[0];
            
            if(file){

                setFile(file);
                let fileExt = file.type.split("/");

                fileExt[0] === "video" ? setType(1) : setType(0);
    
                reader.onload = () => {
                    // image / videio check : type state
                    if(type == 0) { setPreview(reader.result as string)                        
                    } else { setPreview(URL.createObjectURL(file)); }
                };
    
                reader.readAsDataURL(file);
            }
        }
    }

    // is Video? play
    useEffect(() => { 

        if(type == 1) videoRef.current?.load(); 
        if(!id) window.location.href = "/main";

    }, [preview, id])

    return (
        <Container>
            {/* Top Options & Info Section */}
            <Options>

                {/* Delete Button */}
                <DeleteButton
                    onClick={deleteButtonHandle}
                >
                    삭제
                </DeleteButton>

                <SaveButton
                    onClick={saveButtonHandle}
                >
                    저장
                </SaveButton>

            </Options>
            <DataSection>

                <Row height={40}>
                    <Col width={20}>고유번호</Col>
                    <Col width={80}>{ data.id }</Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>형식</Col>
                    <Col width={80}>{ type == 0 ? "이미지 형식" : "동영상 형식"  }</Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>타이틀</Col>
                    <Col width={80}>
                        <textarea 
                            onChange={(e) => titleHandle(e)} 
                            value={ title || "" }
                        />
                    </Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>소제목</Col>
                    <Col width={80}>
                        <textarea 
                            onChange={(e) => contentHandle(e)} 
                            value={ content || "" }
                        />  
                    </Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>작성일</Col>
                    <Col width={80}>{ data.created_at }</Col>
                </Row>

                <Row height={800}>
                    <Col width={20}>
                        첨부파일 <br/>
                        (1920*980 비율 권장)
                    </Col>
                    <Col width={80}>
                        <span>파일을 변경하시려면 클릭하세요.</span>
                        {
                            preview ?
                                type == 0 ?
                                        <img onClick={fileOnClickFileChangeHandle} src={ preview ? preview : data.fileAddr}></img>
                                    :
                                        <video ref={videoRef} controls onClick={fileOnClickFileChangeHandle}>
                                            <source 
                                                src={ preview ? preview : data.fileAddr}
                                                // type="video/mp4"
                                            >
                                            </source>
                                        </video>
                            :
                            <button className="productImageAddBtn" onClick={fileOnClickFileChangeHandle}>파일 추가하기</button>
                        }
                    </Col>
                </Row> 

            </DataSection>

            <input 
                type="file" 
                ref={inputFile} 
                onChange={fileOnChangeHandle}
                style={{display: 'none'}} 
                accept="video/*, image/*" 
            />

        </Container>
    )
}

export default Banner;

const Container = styled.div`

    width: 100%;
    min-width: 500px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

`;

const DataSection = styled.div`

    width: 98%;
    height: calc(100% - 40px);
    margin-top: 50px;
    overflow-y: auto;
    padding-top: 40px;
`;

const Row = styled.div<{ height: number }>`

    width: 100%;
    height: ${props => (props.height ? props.height+"px;" : "40px;" )}

    display: flex;
    flex-direction: row;
    
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 1rem;

`;

const Col = styled.div<{ width: number }>`

    width: ${props => (props.width ? props.width+"%;" : "10%;" )}
    
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px solid #ffffff6e;

    text-align: center;

    overflow-y: auto;

    img {
        width: 320px;
        border-radius: 2px;
    }

    video {
        width: 320px;
        border-radius: 2px;
    }

    textarea {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    span {
        margin-bottom: 20px;
    }

    select {
        width: 100%;
        height: 100%;
        border: none;
        text-align: center;
        background-color: #7c4c4c78;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        appearance: none;
    }

    .productImageAddBtn {
        width: 170px;
        height: 60px;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: bold;
        background-color: white;
        color: black;
    }
`;

// 

const Options = styled.div`

    width: 100%;
    height: 40px;
    min-height: 40px;
    position: fixed;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;

    h4 {
        margin-left: 20px;
        color: red;
    }

`;

const DeleteButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #43424c;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;

const SaveButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #4c486e;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;