import axios, { AxiosResponse } from "axios";
import { viewApi, deleteApi, REFRESH } from "./Config";
import { useEffect, useState } from "react";
import { ListType, ReturnType } from "../interface/Interface";

const axiosObject = axios.create({});
const responseBody = (response: AxiosResponse) => response.data;

const BaseUrl = "https://api.bio3s.com/api/" + window.localStorage.getItem('language') + "/";


/* ---------------------- Use View/Delete/Update/Detail ---------------------- */
// 
export const useViewApi = (menu:string) => {

    const [ data, setData ] = useState<ReturnType>();
    const [ isError, setIsError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    
    const getViewApi = () => {

        try {

            const result = axiosObject.post( viewApi[menu], {}, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            })
            .then(responseBody)
            .catch((res) => {
                if(res.response.status == 401) {
                    if(refreshToken()) { window.location.reload(); } 
                    else {
                        alert("Token invalid");
                        window.location.href = "/";
                    }
                }
            });

            return result;

        } catch (error) {
            console.log(error);
            alert("일시적인 오류로 인해 데이터 수신을 실패하였습니다. [View] ");

        }
    } 
    useEffect(() => {

        const process = async () => {
            
            setIsError(false);      
            setIsLoading(true);

            const data:ReturnType = await getViewApi();

            setData(data);
        }

        setIsLoading(true);

        process();

    }, [menu]);

    return [data, isError, isLoading] as [ReturnType, boolean, boolean];
};

export const useDeleteApi = (menu:string, id:string, file_id:string) => {

    const [ data, setData ] = useState<ReturnType>();
    const [ isError, setIsError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    
    const getDeleteApi = () => {

        try {

            const result = axiosObject.post( deleteApi[menu], {
                banner_id: id,
                file_id: file_id,
            }, 
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            })
            .then(responseBody)

            .catch((res) => {

                if(res.response.status == 401) {

                    if(refreshToken()) { window.location.reload(); } 

                    else {
                        alert("Token invalid");
                        window.location.href = "/";
                    }

                }
            });

            return result;

        } catch (error) {

            console.log(error);
            alert("일시적인 오류로 인해 데이터 수신을 실패하였습니다. [Delete] ");

        }
    } 
    useEffect(() => {

        const process = async () => {
            setIsError(false);      
            setIsLoading(true);
            const data:ReturnType = await getDeleteApi();
            setData(data);
        }

        setIsLoading(true);

        if(id !== '0') { process(); }

    }, [id]);
    return [data, isError, isLoading] as [ReturnType, boolean, boolean];
};

export const useUpdateApi = (menu:string, list:ListType) => {

    const [ data, setData ] = useState<ReturnType>();
    const [ isError, setIsError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    
    const getUpdateApi = () => {
        try {
            const result = axiosObject.post( deleteApi[menu], {}, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            })
            .then(responseBody)
            .catch((res) => {
                if(res.response.status == 401) {
                    if(refreshToken()) { window.location.reload(); } 
                    else {
                        alert("Token invalid");
                        window.location.href = "/";
                    }
                }
            });
            return result;
        } catch (error) {
            console.log(error);
            alert("일시적인 오류로 인해 데이터 수신을 실패하였습니다. [View] ");
        }
    } 
    useEffect(() => {
        const process = async () => {
            setIsError(false);      
            setIsLoading(true);
            const data:ReturnType = await getUpdateApi();
            setData(data);
        }
        setIsLoading(true);
        process();
    }, [menu]);
    return [data, isError, isLoading] as [ReturnType, boolean, boolean];
};

export const useDetailProduct = (id:string) => {

    const [ data, setData ] = useState<ReturnType>();
    const [ isError, setIsError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    
    const url = BaseUrl + "view/product/detail";

    const getViewApi = () => {
        try {
            const result = axiosObject.post( url, {
                id : id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            })
            .then(responseBody)
            .catch((res) => {
                if(res.response.status == 401) {
                    if(refreshToken()) { window.location.reload(); } 
                    else {
                        alert("Token invalid");
                        window.location.href = "/";
                    }
                }
            });
            return result;
        } catch (error) {
            console.log(error);
            alert("일시적인 오류로 인해 데이터 수신을 실패하였습니다. [View] ");
        }
    } 
    useEffect(() => {
        const process = async () => {
            setIsError(false);      
            setIsLoading(true);
            const data:ReturnType = await getViewApi();
            setData(data);
        }
        setIsLoading(true);
        process();
    }, [id]);
    return [data, isError, isLoading] as [ReturnType, boolean, boolean];
};

export const useDetailPopup = (id:string) => {

    const [ data, setData ] = useState<ReturnType>();
    const [ isError, setIsError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    
    const url = BaseUrl + "view/popup/detail";

    const getViewApi = () => {
        try {
            const result = axiosObject.post( url, {
                id : id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            })
            .then(responseBody)
            .catch((res) => {
                if(res.response.status == 401) {
                    if(refreshToken()) { window.location.reload(); } 
                    else {
                        alert("Token invalid");
                        window.location.href = "/";
                    }
                }
            });
            return result;
        } catch (error) {
            console.log(error);
            alert("일시적인 오류로 인해 데이터 수신을 실패하였습니다. [View] ");
        }
    } 
    useEffect(() => {
        const process = async () => {
            setIsError(false);      
            setIsLoading(true);
            const data:ReturnType = await getViewApi();
            setData(data);
        }
        setIsLoading(true);
        process();
    }, [id]);
    return [data, isError, isLoading] as [ReturnType, boolean, boolean];
};

export const historyCategoryApi = async () => {
    
    let url = BaseUrl + "view/historyCategory";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin History Category List]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const boardDetail = async (id:string) => {

    let url = BaseUrl + "view/board/detail";

    try {
        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin History Category List]");
        }

        return result.data;

    } catch (error) {

        return error;
        
    }

};

export const procurmentCategoryData = async (id:string, type:string) => {

    let url = BaseUrl + "view/procurment/category";

    try {
        const result = await axios.post( url, { id: id, type: type }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Procurement Detail]");
        }

        return result.data;

    } catch (error) {

        return error;
        
    }

};

/* ---------------------- Register ---------------------- */
export const registerBanner = async () => {
    
    let url = BaseUrl + "register/banner";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Banner Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerPopup = async () => {
    
    let url = BaseUrl + "register/popup";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Banner Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerPatent = async () => {
    
    let url = BaseUrl + "register/patent";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Banner Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerCertificate = async () => {
    
    let url = BaseUrl + "register/certificate";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Certificate Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

}; 

export const registerGallery = async () => {
    
    let url = BaseUrl + "register/gallery";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Banner Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerBoard = async (type:number) => {
    
    let url = BaseUrl + "register/board";
    try {
        const result = await axios.post( url, { type: type }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Board Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerSocials = async () => {
    
    let url = BaseUrl + "register/socials";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Banner Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

// faq create/register button click
export const registerFaq = async (product_id:string) => {
    
    let url = BaseUrl + "register/faq";

    try {

        const result = await axios.post( url, { id: product_id,}, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {
            alert("추가되었습니다.");
        } else {
            alert("일시적인 오류가 발생되었습니다. [Product in FAQ Add]")
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerProductCertificate = async (product_id:string) => {
    
    let url = BaseUrl + "register/product/certificate";

    try {

        const result = await axios.post( url, { productId: product_id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {
            alert("추가되었습니다.");
        } else {
            alert("일시적인 오류가 발생되었습니다. Admin Register Certificate ID in Product")
        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const registerSnsInProduct = async (product_id:string) => {

    let url = BaseUrl  + "register/product/sns";

    try {

        const result = await axios.post( url, { productId: product_id}, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {
            alert("추가되었습니다.");
        } else {
            alert("일시적인 오류가 발생되었습니다. 새로고침해주세요. \n Admin Delete Certificate ID in Product");
        }

        return result.data;

    } catch (error) {
        return error;
    }
}

export const registerProductId = async () => {

    let url = BaseUrl + "register/product/registerProductId";

    try {

        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. Admin Register Product Id");
            window.location.href = "/main";
        }
        return result.data;

    } catch (error) {
        return error;
    }

}

export const registerQna = async () => {
    
    let url = BaseUrl + "register/qna";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Qna Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerHistory = async () => {
    
    let url = BaseUrl + "register/history";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin History Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerHistoryCategory = async () => {
    
    let url = BaseUrl + "register/historyCategory";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin HistoryCategory Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerProcurment = async () => {
    
    let url = BaseUrl + "register/procurment";
    try {
        const result = await axios.post( url, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Procurment Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerAddRow = async ( detailId : string, type: string ) => {

    // procurement id
    // procurement header id [ status : 0 ]
    // 
    
    let url = BaseUrl + "register/procurment/table/row";
    try {
        const result = await axios.post( url, { id : detailId, type : type }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Procurment Table Row Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerAddColumn = async ( detailId : string, type: string ) => {

    // procurement id
    // procurement header id [ status : 0 ]
    // 
    
    let url = BaseUrl + "register/procurment/table/col";
    try {
        const result = await axios.post( url, { id : detailId, type : type }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Procurment Table Col Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerAddList = async ( ref_id : string, type: string, guideType: number ) => {

    // ref_procurement_id
    // type
    
    let url = BaseUrl + "register/procurement/guide";
    try {
        const result = await axios.post( 
            url, 

            { 
                id : ref_id, 
                type : type,
                guideType: guideType, 
            }, 
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            } 
        );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Procurment Sel Kind Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }

};

export const registerProcurement = async () => {

    
    let url = BaseUrl + "register/procurment";
    try {
        const result = await axios.post(  url, { }, 
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            } 
        );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Procurment Register Button Click]");
        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const registerProductCertificateImage = async (file:any, id:string) => {

    const formData = new FormData();

    formData.append('file', file );
    formData.append('id',   id );

    let url = BaseUrl + "register/certificate/image";
    try {
        const result = await axios.post(  url, formData, 
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                }
            } 
        );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. [Admin Certificate Image Register Button Click]");
        } else {
            alert("이미지가 저장되었습니다.");
        }

        return result.data;

    } catch (error) {
        return error;
    }
};


/* ---------------------- UPDATE ---------------------- */

export const updateBanner = async (data:any) => {

    const formData = new FormData();

    formData.append('id',           data.id );
    formData.append('title',        data.title );
    formData.append('content',      data.content );
    formData.append('ext',          data.ext );
    formData.append('file',         data.file );

    let url = BaseUrl + "update/banner";

    try {  

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Product");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
}

export const updateProduct = async (data:any) => {

    const formData = new FormData();

    formData.append('id',           data.id );
    formData.append('main',         data.main === "메인" ?  "1" : "0" );
    formData.append('category',     data.category );
    formData.append('title',        data.title );
    formData.append('tags',         data.tags );
    formData.append('selLink',      data.selLink );
    formData.append('shortContent', data.shortContent );
    formData.append('file',         data.file );
    formData.append('content',      data.content );
    formData.append('sns',          JSON.stringify(data.sns));
    formData.append('faq',          JSON.stringify(data.faq));

    let url = BaseUrl + "update/product";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        // {
        //     id : data.id,
        //     main : data.main === "메인" ?  "1" : "0",
        //     category : data.category,
        //     title : data.title,
        //     tags : data.tags,
        //     shortContent : data.shortContent,
        //     file : data.file,
        //     content : data.content,
        //     sns : JSON.stringify(data.sns),
        //     faq : data.faq,
        // },

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Product");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updateProductInSocialsImage = async (id:string, file:any) => {

    const formData = new FormData();

    formData.append('id', id );
    formData.append('file', file);

    let url = BaseUrl + "update/product/socialsImage";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Product in Socials Image");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updatePopup = async (data:any) => {

    const formData = new FormData();

    formData.append('id',           data.id );
    formData.append('title',        data.title );
    formData.append('link',         data.link );
    formData.append('file',         data.file );

    let url = BaseUrl + "update/popup";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Popup");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updateQna = async (id:string) => {

    // 답변 완료 처리
    let url = BaseUrl + "update/qna";

    try {

        const result = await axios.post( url, { qna_id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("답변 완료 처리되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update QNA");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updateHistory = async (data:any) => {

    const formData = new FormData();

    formData.append('id',                       data.id );
    formData.append('historyCategoryId',        data.historyCategoryId );
    formData.append('year',                     data.year );
    formData.append('month',                    data.month );
    formData.append('content',                  data.content );

    let url = BaseUrl + "update/history";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Popup");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updatePatent = async (data:any) => {

    const formData = new FormData();

    formData.append('id',               data.id );
    formData.append('title',            data.title );
    formData.append('number',           data.number );
    formData.append('type',             data.type );
    formData.append('register_date',    data.register_date );
    formData.append('country',          data.country );
    formData.append('writer',           data.writer );

    let url = BaseUrl + "update/patent";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Popup");

        }

        return result.data;

    } catch (error) {

        return error;

    } 
};

export const updateCertificate = async (data:any) => {

    const formData = new FormData();

    formData.append('id',               data.id );
    formData.append('type',             data.type );
    formData.append('register_date',    data.register_date );
    formData.append('number',           data.number );
    formData.append('doc_status',       data.doc_status );
    formData.append('number',           data.number );
    formData.append('content',          data.content );

    let url = BaseUrl + "update/certificate";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Certificate");

        }

        return result.data;

    } catch (error) {

        return error;

    } 
};

export const updateGallery = async (data:any) => {

    const formData = new FormData();

    formData.append('id',            data.id );
    formData.append('content',       data.content );
    formData.append('registerDate',  data.registerDate );
    formData.append('docType',       data.docType );
    formData.append('file',          data.file );

    let url = BaseUrl + "update/gallery";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Gallery");

        }

        return result.data;

    } catch (error) {

        return error;

    } 
};

export const updateBoard = async (data:any) => {

    const formData = new FormData();

    formData.append('id',           data.id );
    formData.append('type',         data.type);
    formData.append('title',        data.title );
    formData.append('content',      data.content );

    data.files.map((file:any) => {
        formData.append('files[]', file);
    })
    
    let url = BaseUrl + "update/board";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        // {
        //     id : data.id,
        //     main : data.main === "메인" ?  "1" : "0",
        //     category : data.category,
        //     title : data.title,
        //     tags : data.tags,
        //     shortContent : data.shortContent,
        //     file : data.file,
        //     content : data.content,
        //     sns : JSON.stringify(data.sns),
        //     faq : data.faq,
        // },

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Product");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updateSocials = async (data:any) => {

    const formData = new FormData();

    formData.append('id',        data.id );
    formData.append('link',      data.link);
    formData.append('title',     data.title );
    formData.append('docType',   data.docType );
    formData.append('file',      data.file );
    
    let url = BaseUrl + "update/sns";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update SNS");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updateHistoryCategory = async (data:any) => {

    const formData = new FormData();

    formData.append('id',           data.id );
    formData.append('year_start',   data.startYear);
    formData.append('year_end',     data.endYear );
    formData.append('content',      data.content );

    let url = BaseUrl + "update/historyCategory";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update SNS");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

export const updateProcurement = async (data:any) => {

    const formData = new FormData();

    formData.append('id',                   data.id );
    formData.append('type',                 data.type);
    formData.append('ref_id',               data.ref_id);
    formData.append('status',               data.status );
    formData.append('title',                data.title );
    formData.append('tags',                 data.tags );

    formData.append('shortContent',         data.shortContent );
    formData.append('productImageFile',     data.productImageFile );

    formData.append('deliverGuide',         data.deliverGuide );
    formData.append('contractFile',         data.contractFile );

    formData.append('centerImageFile',      data.centerImageFile );

    formData.append('labelFirstText',      data.labelFirstText );
    formData.append('labelSecondText',     data.labelSecondText );
    formData.append('labelThirdText',      data.labelThirdText );

    formData.append('labelImageFirstFile',  data.labelImageFirstFile );
    formData.append('labelImageSecondFile', data.labelImageSecondFile );
    formData.append('labelImageThirdFile',  data.labelImageThirdFile );

    data.table.map((item:any)           => { formData.append('table[]',         JSON.stringify(item)); });
    data.tableHeader.map((item:any)     => { formData.append('tableHeader[]',   JSON.stringify(item)); });
    data.selKindList.map((item:any)     => { formData.append('selKindList[]',   JSON.stringify(item)); });
    data.selOptionList.map((item:any)   => { formData.append('selOptionList[]', JSON.stringify(item)); });
    data.labelExList.map((item:any)     => { formData.append('labelExList[]',   JSON.stringify(item)); });


    let url = BaseUrl + "update/procurement";

    try {

        const result = await axios.post( url,  formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("저장되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Update Procurement");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

/* ---------------------- DELETE ---------------------- */

export const deleteBanner = async (banner_id:string, file_id:string) => {

    let url = BaseUrl + "delete/banner";

    try {

        const result = await axios.post( url, { banner_id: banner_id, file_id: file_id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Banner");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

// faq delete button click
export const deleteFaq = async (id:string) => {
    
    let url = BaseUrl + "delete/faq";
    try {
        const result = await axios.post( url, { id: id,}, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {
            alert("정상적으로 삭제되었습니다.");
        }

    } catch (error) {
        return error;
    }

};

// product in certificate Delete, Add Function
export const deleteProductCertificate = async (product_id:string, certficate_id:string) => {

    let url = BaseUrl + "product/certificate";

    try {

        const result = await axios.post( url, { productId: product_id, certficateId: certficate_id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {
            alert("삭제되었습니다.");
        } else {
            alert("일시적인 오류가 발생되었습니다. Admin Delete Certificate ID in Product")
        }

    } catch (error) {
        return error;
    }
};

export const deleteSnsInProduct = async (product_id:string, sns_id:string) => {

    let url = BaseUrl + "delete/product/sns";

    try {

        const result = await axios.post( url, { productId: product_id, snsId: sns_id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {
            alert("삭제되었습니다.");
        } else {
            alert("일시적인 오류가 발생되었습니다. Admin Delete Certificate ID in Product")
        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteProduct = async (productId:string) => {

    let url = BaseUrl + "delete/product";

    try {

        const result = await axios.post( url, { product_id: productId }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Product");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deletePopup = async (id:string) => {

    let url = BaseUrl + "delete/popup";

    try {

        const result = await axios.post( url, { popup_id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Product");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteQna = async (id:string) => {

    let url = BaseUrl + "delete/qna";

    try {

        const result = await axios.post( url, { qna_id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete QNA");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteHistory = async (id:string) => {

    let url = BaseUrl + "delete/history";

    try {

        const result = await axios.post( url, { history_id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete QNA");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deletePatent = async (id:string) => {

    let url = BaseUrl + "delete/patent";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete PATENT");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteCertificate = async (id:string) => {

    let url = BaseUrl + "delete/certificate";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Certificate");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteGallery = async (id:string) => {

    let url = BaseUrl + "delete/gallery";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Gallery");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteBoardFile = async (id:string) => {

    let url = BaseUrl + "delete/board/file";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Gallery");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteBoard = async (id:string) => {

    let url = BaseUrl + "delete/board";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Board");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteSocials = async (id:string) => {

    let url = BaseUrl + "delete/sns";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete SNS");

        }

        return result.data;

    } catch (error) {
        return error;
    }
};

export const deleteHistoryCategory = async (id:string) => {

    let url = BaseUrl + "delete/historyCategory";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete SNS");

        }

        return result.data;

    } catch (error) {
        return error;
    }
}

export const deleteProcurmentTableRow = async (id:number) => {

    let url = BaseUrl + "delete/table/row";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. Admin Delete Procurement Table Row");
        }

        return result.data;

    } catch (error) {
        return error;
    }
}

export const deleteProcurmentTableCol = async (id:number) => {

    let url = BaseUrl + "delete/table/col";

    try {

        const result = await axios.post( url, { id: id }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. Admin Delete Procurement Table Col");
        }

        return result.data;

    } catch (error) {
        return error;
    }
}

export const deleteProcurmentGuide = async (id:string, type:string) => {

    let url = BaseUrl + "delete/procurment/guide";

    try {

        const result = await axios.post( url, 
            { 
                id: id,
                type: type,
            }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. Admin Delete Procurement Guide");
        }

        return result.data;

    } catch (error) {
        return error;
    }
}

export const deleteProcurment = async (id:string) => {

    let url = BaseUrl + "delete/procurment";

    try {

        const result = await axios.post( url, 
            { 
                id: id,
            }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. Admin Delete Procurement Guide");
        }

        return result.data;

    } catch (error) {
        return error;
    }
}

export const deleteCertificateImageRemove = async (id:any) => {

    let url = BaseUrl + "delete/certificate/image";

    try {

        const result = await axios.post( url,  {
            id: id,
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(result.data.success) {

            alert("삭제되었습니다.");

        } else {

            alert("일시적인 오류가 발생되었습니다. Admin Delete Certificate Image");

        }

        return result.data;

    } catch (error) {
        return error;
    } 
};

/* ---------------------- Common ---------------------- */
export const searchTable = async (type:string, keyword:string) => {

    let url = BaseUrl + "search";

    try {

        const result = await axios.post( url,  {type: type, keyword: keyword}, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        } );

        if(!result.data.success) {
            alert("일시적인 오류가 발생되었습니다. Admin Search [ " + type + " ]");
        }

        return result.data;

    } catch (error) {
        return error;
    } 
}

const refreshToken = () => {

    let success = false;

    axios.post(REFRESH , {
        refresh_token: window.localStorage.getItem("refresh_token")
    })
    
    .then((Response) => {
        
        if (Response.data.success) {

            localStorage.setItem('access_token', Response.data.data.token.access_token);
            localStorage.setItem('refresh_token', Response.data.data.token.refresh_token);

            success = true;

        } else {

            if (!Response.data.data.msg) {
                alert(Response.data.msg);
            }

            success = false;
        }
    })
    .catch((Error) => { console.log(Error) })

    return success;
};