import axios from "axios";
import { BASE_URL } from "./Config";

export const LoginAccess = async (id:string, password:string) => {

    try {
        const result = await axios.post( "https://api.bio3s.com/api/" + "login", { id: id, password: password} );
        return result.data;

    } catch (error) {
        return error;
    }

};