import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { deleteCertificateImageRemove, deleteFaq, 
    deleteProduct, 
    deleteProductCertificate, 
    deleteSnsInProduct, 
    registerCertificate, 
    registerFaq, 
    registerProductCertificate, 
    registerProductCertificateImage, 
    registerSnsInProduct, 
    updateProduct, 
    updateProductInSocialsImage,  
    useDetailProduct 
} from "../../api/Apis";

import { ListType } from "../../interface/Interface";
import emptyImage from "../../assets/images/empty_image.png";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import axios, { AxiosError } from "axios";
import { useRecoilState } from "recoil";
import { language } from "../../state/store";

const Product = ( data:ListType ) => {

    const [ languageType, setLanguage ] = useRecoilState(language);
    
    const [ title,    setTitle ]    = useState<string | null>(data.title || "");
    const [ shortContent,  setShortContent]   = useState<string | null>(data.short_content || "");
    const [ content,  setContent]   = useState<string | null>(data.content || "");

    let isMainhidden = "0";
    if(data.status == "1" ) isMainhidden = "메인";

    const [ isMainStatus,   setMainStatus ]     = useState<string | null>(isMainhidden);
    const [ category,       setCategory]        = useState<string | null>(data.category || "화장품");
    const [ tags,           setTags]            = useState<string | null>(data.tags || "");
    const [ file,           setFile ]           = useState<File   | null>(null);
    const [ sel_link,       setSelLink]         = useState<string | null>(data.sel_link || "");

    const [ preview,        setPreview ]        = useState<string | null>(data.fileAddr);
    const [ type,           setType ]           = useState<number>(Number(data.type));
    const [ certificates,   setCertificate ]    = useState([]);

    //
    const [ file_id,        setFileId ]         = useState<string>('0');

    //
    const [ faqList,        setFaqList ]        = useState<any[]>([]);
    const [ newFaqList,     setNewFaqList ]     = useState<any[]>([]);
    const [ deleteFaqList,  setdeleteFaqList ]  = useState<any[]>([]);

    //
    const [ sns,            setSns ]            = useState<any[]>([]);
    const [ registerSns,    setRegisterSns ]    = useState<any[]>([]);

    const [ delSnsImage,    setDelSnsImage ]    = useState<any[]>([]);
    const [ updateSnsImage, setUpdateSnsImage ] = useState<any[]>([]);
    

    // useRef List
    const inputFile = useRef<HTMLInputElement>(null);
    const videoRef  = useRef<HTMLVideoElement>(null);
    const QuillRef  = useRef<ReactQuill>();
    const faqTitleRef = useRef<HTMLTextAreaElement[] | null>([]);
    const faqContentRef = useRef<HTMLTextAreaElement[] | null>([]);

    // fixed Menu Category
    const mainCategory = ["일반", "메인"];
    const productCategory = ["화장품", "의약외품", "위생용품", "공산품"];

    // custom hook
    const useDetail = useDetailProduct(data.id);
    const productDetail:any = useDetail[0];
    
    //  handle function
    const titleHandel           = (e:React.ChangeEvent<HTMLInputElement>)   => { setTitle(e.target.value); };
    const isMainChangeHandle    = (e:any)                                   => { setMainStatus(e.target.value); }
    const categoryChangeHandle  = (e:any)                                   => { setCategory(e.target.value); }
    const tagsHandle            = (e:React.ChangeEvent<HTMLInputElement>)   => { setTags(e.target.value); };
    const selLinkHandle         = (e:React.ChangeEvent<HTMLInputElement>)   => { setSelLink(e.target.value); };
    const shortContentHandle    = (e:React.ChangeEvent<HTMLInputElement>)   => { setShortContent(e.target.value); };
    
    const deleteButtonHandle = () => {  

        if(window.confirm("정말로 삭제하시겠습니까?")) {
            deleteProduct(data.id).then(res => {
                if(res.success) {
                    window.location.href = "/main";
                }
            });
        }

    };
    const saveButtonHandle = () => {  



        /* Call save(update) Api */  
        let updateData:any = [];

        updateData['id']              = data.id;
        updateData['main']            = isMainStatus;
        updateData['category']        = category;
        updateData['title']           = title;
        updateData['tags']            = tags;
        updateData['selLink']         = sel_link;
        updateData['shortContent']    = shortContent;
        updateData['file']            = file;
        updateData['socialsFiles']    = updateSnsImage;
        updateData['content']         = content;
        updateData['sns']             = sns;
        updateData['faq']             = faqList;

        // isMainStatus 메인 노출 여부
        // category     상품 카테고리 설정
        // title        상품 명칭
        // tags         상품 태그
        // shortContent 상품 짧은 설명글
        // file         상품 대표 이미지
        // content      상품 상세 설명
        // sns          상품 내 소셜 컨텐츠
        // faqList      상품 내 FAQ  

        updateProduct(updateData).then(res => {
            if(res.success) {
                window.location.href = "/main";
            }
        });

    };
    // handle Ref input file click
    const fileOnClickFileChangeHandle = () => { 
        if(inputFile.current) { 
            inputFile.current.click(); 
        } 
    };
    // handle file change
    const fileOnChangeHandle = () => {

        if(inputFile.current?.files) {

            let reader = new FileReader();
            let file = inputFile.current.files[0];
            
            if(file){

                setFile(file);

                let fileExt = file.type.split("/");

                fileExt[0] === "video" ? setType(1) : setType(0);
    
                reader.onload = () => {
                    // image / videio check : type state
                    if(type == 0) { setPreview(reader.result as string)                        
                    } else { setPreview(URL.createObjectURL(file)); }
                };
    
                reader.readAsDataURL(file);
            }
        }
    }

    /* --- Editor Image Save API [ to server ] --- */ 
    const imageHandler = () => {

        // 파일을 업로드 하기 위한 input 태그 생성
        const input = document.createElement("input");
        let url = "https://api.bio3s.com/api/" + languageType + "/register/editor/image";

        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // 파일이 input 태그에 담기면 실행 될 함수 
        input.onchange = async () => {

            const file = input.files;

            if (file !== null) {

                try {

                    const res = axios.post(
                                url, 
                                {   
                                    id: data.id,
                                    type: "editor",
                                    image: file[0],
                                }, 
                                {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                                    }
                                }).then((res) => {

                                    url = res.data.data.url;
                                    const range = QuillRef.current?.getEditor().getSelection()?.index;

                                    if (range !== null && range !== undefined) {

                                        let quill = QuillRef.current?.getEditor();

                                        quill?.setSelection(range, 1);
                                        
                                        quill?.clipboard.dangerouslyPasteHTML(
                                        range,
                                        `<img src=${url} />`
                                        );

                                    }
                                });

                    return { ...res, success: true };

                } catch (error) {

                    const err = error as AxiosError;
                    return { ...err.response, success: false };

                }
            }
        };
    };
    // react quill init
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { size: ["small", false, "large", "huge"] }, 
                        { color: ["red", "blue", "yellow", "pink", "black"] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    ["image", "video"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
    } },[]);
    // faq Add button on click [ to server ]
    const faqsAddSection = () => {

        const resultFaqId = registerFaq(data.id);

        const faqId = resultFaqId.then((res) => {
            
            if(res.success) {
                let addFaq = {id: res.data.id, title:"", content:"", };
                
                let tempFaqList:any = faqList;

                setFaqList([...tempFaqList, addFaq]);

                setNewFaqList([...newFaqList, addFaq]);
            }
        })
    
    };
    // faq Delete button on click [ to server ]
    const deletFaq = (id:string) => {

        let splitCheck:string[] = id.toString().split('_');

        // 
        if(splitCheck.length > 1) {

            let tempFaqList:any = [];

            faqList.map((item:any, index:number) => {

                if(item.id === id) {

                    setdeleteFaqList([...deleteFaqList, faqList[index]]);

                } else {

                    tempFaqList.push(item);

                }
            })

            setFaqList(tempFaqList);
            return;
        }

        //
        if( window.confirm("정말로 삭제하시겠습니까?" )) {

            if(splitCheck.length < 2) {

                deleteFaq(id);

            }

            let tempFaqList:any = [];

            faqList.map((item:any, index:number) => {

                if(item.id === id) {

                    setdeleteFaqList([...deleteFaqList, faqList[index]]);

                } else {

                    tempFaqList.push(item);

                }
            })

            setFaqList(tempFaqList);
            return;
        }
    };
    // faq textarea title onchange
    const faqListTitleOnChage = (e:any, id:string) => {
        
        let tempFaqList:any = [];
        faqList.map((item:any, index:number) => {
            if(item.id === id) {
                tempFaqList.push({id:item.id, title: e.target.value, content:item.content});
            } else {
                tempFaqList.push(item);
            }
        });
        setFaqList(tempFaqList);

    };
    // faq textarea content onchange
    const faqListContentOnChage = (e:any, id:string) => {
        
        let tempFaqList:any = [];
        faqList.map((item:any, index:number) => {
            if(item.id === id) {
                tempFaqList.push({id:item.id, title: item.title, content: e.target.value});
            } else {
                tempFaqList.push(item);
            }
        });
        setFaqList(tempFaqList);

    };
    // 
    const snsTitleOnChangeHandle = (item:any, e:any) => {

        let snsTempArray:any = [];

        sns.map((ele:any) => {
            if(ele.id === item.id) {
                snsTempArray.push({
                    id: ele.id,
                    title: e.target.value,
                    link: ele.link,
                    origin_filename: item.origin_filename,
                    fileAddr: item.fileAddr,
                    ext: item.ext
                });
            } else {
                snsTempArray.push(ele);
            }
        });

        setSns(snsTempArray);

    };
    //
    const snsLinkOnChangeHandle = (item:any, e:any) => {

        let snsTempArray:any = [];

        sns.map((ele:any) => {
            if(ele.id === item.id) {
                snsTempArray.push({
                    id: ele.id,
                    title: ele.title,
                    link: e.target.value,
                    origin_filename: item.origin_filename,
                    fileAddr: item.fileAddr,
                    ext: item.ext
                });
            } else {
                snsTempArray.push(ele);
            }
        });

        setSns(snsTempArray);

    };
    // 상품 내 소셜 리스트 삭제 [ to server ]
    const snsDeleteOnClickHandle = (snsId:string) => {

        deleteSnsInProduct(data.id, snsId).then((res:any) => {

            if(res.success) {

                let tempSnsList:any = [];
                let tempDelSnsList:any = [];

                sns.map((ele:any) => {

                    if(ele.id != snsId) {

                        tempSnsList.push(ele);

                    }

                });            

                setSns(tempSnsList);
            }

        });

    };
    // 상품 내  소셜 추가 버튼 [ to server ]
    const snsAddOnClickHandle = (e:any) => {

        registerSnsInProduct(data.id).then((res) => {

            setSns([...sns, 
                { 
                    id: res.data.id, 
                    title:"", 
                    link: "", 
                    origin_filename:"", 
                    ext:"" 
                }
            ]);

        });

    };
    // 소셜 이미지 변경을 위해 첨부파일 클릭 시 동작
    const snsImageChangeOnClickHandle = (item:any) => {

        let tempSnsList:any = [];

        sns.map((ele:any, index:number) => {

            if(ele.id != item.id) {

                tempSnsList.push(ele);

            } else {

                tempSnsList.push(

                    {
                        id:ele.id, 
                        title:ele.title,
                        link:ele.link, 
                        fileAddr: "",
                        origin_filename: "",
                        ext: "",
                    }

                );
            }

        });

        setDelSnsImage([...delSnsImage, item]);
        setSns(tempSnsList);

    };
    // 파일 브라우저 오픈 이후, 파일 선택 여부에 따른 동작 정의 함수
    const snsImageFileChangeOnclickHandle  = (item:any, e:any) => {
        
        
        if(e.target.files[0]) {

            // console.log(e.target.files[0]);

            // 소셜 이미지 다이렉트 저장
            updateProductInSocialsImage(item.id, e.target.files[0]);

            let tempUpdateSnsImage:any = [];

            // sns array update
            sns.map((ele:any) => {
                if(ele.id == item.id) {
                    tempUpdateSnsImage.push({ id: item.id, origin_filename: e.target.files[0].name});
                } else {
                    tempUpdateSnsImage.push(ele);
                }
            })

            setUpdateSnsImage([...updateSnsImage, { id: item.id, file: e.target.files[0] }])

        } else {
            
            let tempUpdateSnsImage:any = [];

            updateSnsImage.map((ele:any, index:number) => {
                if(ele.id != item.id) {
                    tempUpdateSnsImage.push(ele);
                }
            });

            setUpdateSnsImage(tempUpdateSnsImage);

        } // if - else end

    }; // const end

    // faq 초기화
    if(faqList.length < 1) {  
        if(productDetail && deleteFaqList.length < 1) {
            if(productDetail.data.faqs.length > 0) {
                setFaqList(productDetail.data.faqs);
            }
        }
    };

    // 등록된 소셜 데이터 초기화
    if(sns.length < 1) {
        if(productDetail) {
            if(productDetail.data.sns) {
                if(productDetail.data.sns.length > 0) {
                    setSns(productDetail.data.sns);
                }
            }
        }
    };
    
    // 등록된 인증서 초기화
    if(certificates.length < 1) {
        if(productDetail) {
            if(productDetail.data.certificate) {
                if(productDetail.data.certificate.length > 0) {
                    setCertificate(productDetail.data.certificate);
                }
            }
        }
    };

    //
    const certificateAddButtonOnClickHandle = (e:any) => {
        // 
        registerProductCertificate(data.id).then((res) => {

            let tempArray:any = [];

            certificates.map((item:any, index:number) => {
                tempArray.push(item);
            })

            tempArray.push({
                id: res.data.id,
                content: '',
                fileAddr: '',
                origin_filename: '',
            });

            setCertificate(tempArray);

        })
    };

    const certificateRemoveOnClickHandle = (e:any, item:any) => {

        deleteCertificateImageRemove(item.id).then((res) => {

            if(res.success) {

                let tempArray:any = [];

                certificates.map((ele:any, index:number) => {

                    if(item.id != ele.id) {

                        tempArray.push(ele);

                    }
                })

                if(tempArray.length < 1) {
                    setCertificate([]);
                    return;
                }
                setCertificate(tempArray);

            }

        });
            
    }

    const certificateImageSaveOnChangeHandle = (e:any, item:any) => {

        if(e.target.files.length > 0) {

            registerProductCertificateImage(e.target.files[0], item.id).then((res) => {

                let tempArray:any = [];

                certificates.map((ele:any, index:number) => {

                    if(item.id != ele.id) {

                        tempArray.push(ele);

                    } else {

                        tempArray.push({
                            id: ele.id,
                            content: ele.content,
                            fileAddr: res.data.fileAddr,
                            origin_filename: res.data.origin_filename,
                        });

                    }
                })

                setCertificate(tempArray);

            })

        }

    }

    const certificateImageCleanOnClickHandle = (e:any, item:any) => {

        let tempArray:any = [];

        certificates.map((ele:any, index:number) => {

            if(item.id == ele.id) {

                tempArray.push({
                    id: ele.id,
                    content: ele.content,
                    fileAddr: '',
                    origin_filename: '',
                });

            }
        })

        setCertificate(tempArray);

    }


    // is Video? play
    useEffect(() => { 

        if(type == 1) videoRef.current?.load(); 
        if(!data.id) {
            alert("상품 ID를 확인할 수 없습니다. [Product Detail Missing data.id]");
            window.location.href = "/main";
        }

    }, []);


    return (
        <Container>

            {/* Top Options & Info Section */}
            <Options>

                {/* Delete Button */}
                <DeleteButton
                    onClick={deleteButtonHandle}
                >
                    삭제
                </DeleteButton>

                <SaveButton
                    onClick={saveButtonHandle}
                >
                    저장
                </SaveButton>

                <h4> * 상품명이 공란인 경우 사용자 화면에 노출되지 않습니다. ( 임시저장 형태로 활용하실 수 있습니다 ) </h4>
            </Options>

            <DataSection>

                {/* Data Id */}
                <Row height={40}>
                    <Col width={20}>고유번호</Col>
                    <Col width={80}>{ data.id }</Col>
                </Row>

                {/* 메인화면 노출 여부 */}
                <Row height={40}>
                    <Col width={20}>메인여부</Col>
                    <Col width={80}>
                        <select defaultValue={data.status == '0' ? "일반" : "메인"} onChange={(e) => isMainChangeHandle(e)}>
                            {
                                mainCategory.map((item:string, index:number) => {
                                    return <option key={index} value={item} >{item}</option>
                                })
                            }
                        </select>
                    </Col>
                </Row>

                {/* 카테고리 설정 */}
                <Row height={40}>
                    <Col width={20}>카테고리</Col>
                    <Col width={80}>

                        <select defaultValue={data.category} onChange={(e) => categoryChangeHandle(e)}>
                            {
                                productCategory.map((item:string, index:number) => {
                                    return <option key={index} value={item} >{item}</option>
                                })
                            }
                        </select>
                        
                    </Col>
                </Row>

                {/* 상품 제목 설정 */}
                <Row height={40}>
                    <Col width={20}>상품명</Col>
                    <Col width={80}>
                        <input type="text" onChange={(e) => titleHandel(e)} value={ title ?  title : data.title ? data.title : "" || "" }/>
                    </Col>
                </Row> 

                {/* 태그 설정 */}
                <Row height={40}>
                    <Col width={20}>태그</Col>
                    <Col width={80}>
                        <input type="text" onChange={(e) => tagsHandle(e)} value={ tags ? tags : data.tags || "" }/>
                    </Col>
                </Row> 

                {/* 구매링크 설정 */}
                <Row height={40}>
                    <Col width={20}>구매링크</Col>
                    <Col width={80}>
                        <input type="text" onChange={(e) => selLinkHandle(e)} value={ sel_link ? sel_link : data.sel_link || "" }/>
                    </Col>
                </Row> 

                {/* 소제목 설정 */}
                <Row height={40}>
                    <Col width={20}>소제목</Col>
                    <Col width={80}>
                        <input type="text" onChange={(e) => shortContentHandle(e)} value={ shortContent ? shortContent : data.short_content ? data.short_content : "" }/>  
                    </Col>
                </Row>

                {/* created_at */}
                <Row height={40}>
                    <Col width={20}>작성일</Col>
                    <Col width={80}>{ data.created_at }</Col>
                </Row>
                
                {/* 상품 대표 이미지 설정 */}
                <Row height={320}>
                    <Col width={20}>
                        상품이미지 <br/> 
                        (이미지을 통해 사진을 변경할 수 있습니다.) <br/>
                        * 상단 저장 버튼을 눌러주셔야 사진이 변경됩니다.
                    </Col>
                    <Col width={80}>
                        { preview ? 
                            <img onClick={fileOnClickFileChangeHandle} src={ preview ? preview : data.fileAddr}></img>
                            :
                            <button className="productImageAddBtn" onClick={fileOnClickFileChangeHandle}>사진 추가하기</button>
                        }
                        
                    </Col>
                </Row>

                {/* 상세 내용 */}
                <Row height={800}>
                    <Col width={20}>상세내용</Col>
                    <Col width={80}>
                        <CustomReactQuill 
                            ref={(element) => {
                                if(element !== null) {
                                    QuillRef.current = element;
                                }
                            }}
                            value={ content ? content : data.content}
                            onChange={setContent}
                            modules={modules}
                            theme="snow"
                            placeholder="내용을 입력해주세요."
                        />
                    </Col>
                </Row>

                {/* 소셜 작성 */}
                <Row height={500}>
                    <Col width={20}>
                        소셜 <br/>
                        (추가/삭제 및 이미지 변경은 <br/>
                        바로 저장됩니다.) <br/>
                        * SNS 내용 변경/저장은 <br/>
                        상단 저장 버튼을 이용해주세요.

                        <button 
                            className="addBtn"
                            onClick={(e) => snsAddOnClickHandle(e)}
                        >
                            추가하기
                        </button>
                    </Col>
                    <Col width={80}>

                         <div className="snsContainer">

                            {
                                sns.map((item:any, index:number) => {

                                    return <div className="snsBox" key={index}>

                                                <div className="snsDelete">
                
                                                    <span>SNS</span>
                                                    <button
                                                        onClick={() => snsDeleteOnClickHandle(item.id)}
                                                    >
                                                        삭제하기
                                                    </button>
                
                                                </div>
                
                                                <div className="snsData">
                
                                                    <input 
                                                        type="text" 
                                                        placeholder="소셜 제목을 입력해주세요.(20자이하)"
                                                        onChange={(e) => snsTitleOnChangeHandle(item, e)}
                                                        defaultValue={item.title}
                                                    />

                                                    <input 
                                                        type="text" 
                                                        placeholder="링크를 입력해주세요.(* https:// 포함)"
                                                        onChange={(e) => snsLinkOnChangeHandle(item, e)}
                                                        defaultValue={item.link}
                                                    />

                                                    {
                                                        item.origin_filename ?

                                                            <button 
                                                                className="snsFile"
                                                                onClick={() => snsImageChangeOnClickHandle(item)}
                                                            >
                                                                첨부파일 : {item.origin_filename}.{item.ext} (클릭 시 파일 변경 가능)
                                                            </button>
                                                        :
                                                            <div className="snsFileUploadBox">

                                                                {/* { 

                                                                    item.id ?
                                                                        
                                                                        delSnsImage.map((ele:any, j:number) => {
                                                                            if(item.id == ele.id) {
                                                                                return <button key={j} className="initBtn" onClick={(e) => snsImageFileInitOnClickHandle(item)}>이전</button>
                                                                            }
                                                                        })

                                                                    :

                                                                    ""

                                                                } */}
                                                                
                                                                <input 
                                                                    type="file"
                                                                    accept="image/*"
                                                                    onChange={(e) => snsImageFileChangeOnclickHandle(item, e)}
                                                                />

                                                            </div>
                                                    }

                                                </div>

                                            </div>
                                    // end return in map
                                })
                            }
                            
                         </div>
                    </Col>
                </Row>

                {/* 인증서 관리 */}
                <Row height={800}>
                    <Col width={20}>
                        인증서 <br/>
                        (추가/삭제 시 바로 저장됩니다.)

                        <button 
                            className="addBtn"
                            onClick={(e) => certificateAddButtonOnClickHandle(e)}
                        >
                            추가하기
                        </button>
                    </Col>
                    <Col width={80}>

                        <div
                            className="certificateContainer"
                        >
                            {
                                certificates.map((item:any, index:number) => {

                                    return (

                                        <div
                                            key={index}
                                            className="certificateItemBox"
                                        >
                                            {
                                                item.fileAddr ?
                                                    <div
                                                        className="certificateAddr"
                                                        onClick={(e) => certificateImageCleanOnClickHandle(e, item)}
                                                    >
                                                        <span className="certificateFileNameColor">
                                                            {item.origin_filename} 
                                                        </span>
                                                        (* 클릭 시, 파일을 교체하실 수 있습니다)
                                                    </div>
                                                :
                                                    <input 
                                                        type="file" 
                                                        className="certificateInputFile"
                                                        onChange={(e) => certificateImageSaveOnChangeHandle(e, item)}
                                                    />
                                            }
                                            <button
                                                className="certificateRemoveClass"
                                                onClick={(e) => certificateRemoveOnClickHandle(e, item)}
                                            >
                                                삭제하기
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </Col>
                </Row>

                {/* FAQ 관리 */}
                <Row height={800}>
                    <Col width={20}>

                        FAQ <br/>
                        (추가/삭제 시 바로 저장됩니다.) <br/>
                        * FAQ 내용 입력 후 상단 저장하기 버튼을 눌러주세요.

                        <button className="addBtn" onClick={faqsAddSection}>
                                추가하기 <br/>
                        </button>

                    </Col>
                    <Col width={80}>

                        <div className="faqsContainer">
                            
                            {
                                
                                faqList.map((item:any, index:number) => {

                                    return <div className="faqsBox" key={index}>
                                                
                                                <div className="label">

                                                    <span>FAQ</span>
                                                    <button onClick={() => deletFaq(item.id)}>
                                                        삭제하기
                                                    </button>

                                                </div>
            
                                                <div className="faqList">

                                                    <textarea 
                                                        // ref={ ref => faqTitleRef.current?.push(ref!) }
                                                        ref={ ref => faqTitleRef.current![index] = ref! }
                                                        id={item.id}
                                                        className="faqs" 
                                                        value={faqList[index].title || ''}
                                                        onChange={(e) => faqListTitleOnChage(e, item.id)}
                                                        placeholder={"내용을 입력하세요."}
                                                    />
                                                    
                                                    <textarea 
                                                        //ref={ ref => faqContentRef.current?.push(ref!) }
                                                        ref={ ref => faqContentRef.current![index] = ref! }
                                                        id={item.id}
                                                        className="faqs"
                                                        value={faqList[index].content || ''}
                                                        onChange={(e) => faqListContentOnChage(e, item.id)}
                                                        placeholder={"내용을 입력하세요."}
                                                    />

                                                </div>
            
                                            </div>
                                })

                            }

                        </div>
                    </Col>
                </Row>

            </DataSection>
            
            {/* 상품 대표 이미지 설정 */}
            <input 
                type="file" 
                ref={inputFile} 
                onChange={fileOnChangeHandle}
                style={{display: 'none'}} 
                accept="video/*, image/*" 
            />

        </Container>
    )
}

export default Product;

//
const Container = styled.div`

    width: 100%;
    min-width: 500px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

`;

//
const DataSection = styled.div`

    width: 98%;
    height: calc(100% - 40px);
    margin-top: 50px;
    overflow-y: auto;
    padding-top: 40px;
`;

//
const Row = styled.div<{ height: number }>`

    width: 100%;
    min-height: 40px;

    height: ${props => (props.height ? props.height+"px;" : "40px;" )}

    display: flex;
    flex-direction: row;
    
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 1rem;

`;

//
const Col = styled.div<{ width: number }>`

    width: ${props => (props.width ? props.width+"%;" : "10%;" )}

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: thin solid #ffffff6e;

    text-align: center;

    overflow-y: auto;

    .productImageAddBtn {
        width: 170px;
        height: 60px;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: bold;
        background-color: white;
        color: black;
    }

    img {

        width: 320px;
        border-radius: 2px;

    }

    video {

        width: 320px;
        border-radius: 2px;

    }

    textarea {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    input {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    span {

        margin-bottom: 20px;

    }

    select {

        width: 100%;
        height: 100%;
        border: none;
        text-align: center;
        background-color: #7c4c4c78;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        appearance: none;

    }

    .addBtn {
        width: 40%;
        height: 30px;
        margin-bottom: 5px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;

        font-weight: bold;
        font-size: 1rem;

        &:hover {
            cursor: pointer;
        }
    }

    .faqsContainer {

        width: 100%;
        height: 100%;
    
        .faqsBox {

            border: thin solid black;
            width: 100%;
            height: 200px;

            display: flex;
            flex-direction: row;

            margin-bottom: 5px;

            .label {

                width: 120px;
                display: flex;
                flex-direction: column;

                align-items: center;
                justify-content: center;

                background-color: black;

                button {

                    width: 60%;
                    height: 30px;
                    background-color: red;
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                    }
                }

            }

            .faqList {
                
                width: calc(100% - 120px);
                height: 200px;
                
                .faqs {
                    width: 100%;
                    height: 49%;
                    text-align: left;
                    padding-left: 5px;
                }
            }
            
        }

    }

    .certificateContainer {

        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;

        .certificateItemBox {

            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;

            .certificateAddr {

                width: 90%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                .certificateFileNameColor {
                    color: red;
                    font-weight: bold;
                    margin-right: 5px;
                    margin-bottom: 0px;
                }

            }

            .certificateInputFile {
                width: 90%;
                height: 100%;
            }

            .certificateRemoveClass {
                width: 10%;
                height: 100%;
                background-color: red;
                border:none;
                color: white;
                font-weight: bold;
                font-size: 1rem;
            }
        }

    }

    .snsContainer {

        width: 100%;
        height: 100%;

        .snsBox { 

            width: 100%;
            height: 140px;
            position: relative;

            display: flex;
            flex-direction: row;

            margin-bottom: 10px;

            .snsDelete {

                width: 120px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: black;

                button { 
                    width: 60%;
                    height: 30px;
                    background-color: red;
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-weight: bold;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .snsData {

                width: calc(100% - 120px);

                input {
                    height: calc(100% / 3);
                    font-size: 1rem;
                }

                .snsFile {

                    text-align: left;
                    width: 100%;
                    height: calc(100% / 3); 
                    padding-left: 20px;
                    
                    color: white;
                    font-size: 1rem;
                    font-weight: bold;

                    background-color: gray;

                    &:hover {
                        cursor: pointer;
                    }

                }

                .snsFileUploadBox {
                    
                    display: flex;
                    align-items: center;

                    width: 100%;
                    height: calc(100% / 3); 
                    color: white;
                    font-size: 1rem;
                    font-weight: bold;

                    background-color: gray;

                    input {
                        display: inline;
                        width: 100%;
                        height: auto;
                        background-color: transparent;
                        margin-left: 10px;
                    }

                    .initBtn {
                        border:none;
                        border-radius: 5px;
                        width: 60px;
                        height: 25px;
                        font-size: 0.9rem;
                        font-weight: bold;
                        background-color: #7c4a7f;
                        margin-left: 10px;
                        color: white;

                        &:hover {
                            cursor: pointer;
                        }

                    }

                }
            }

        }

    }
    
`;

// 
const Options = styled.div`

    width: 100%;
    height: 40px;
    min-height: 40px;
    position: fixed;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;

    h4 {
        margin-left: 20px;
        color: red;
    }
`;

//
const DeleteButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #43424c;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;

//
const SaveButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #4c486e;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;

//
const CustomReactQuill = styled(ReactQuill)`

    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    border:none;

    .quill {
        background-color: gray;
    }

    .ql-container {
        border: none;
    }
    
    .ql-toolbar {
        display: flex;
        fill: white;
        background-color: white;
    }

    .ql-editor {
        background-color: white;
        color: black;
        font-weight: normal;
    }

`;