import React from 'react';
import styled from "styled-components";


const Input = ( {className, value, placeholder, handleChange} :
    {   
        className:string,
        value:string,
        placeholder:string , 
        handleChange:React.ChangeEventHandler
        
    }) => {

    return(

        <InputComponent
            type="text"
            className={className}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
        />

    )
}

export default Input;


const InputComponent = styled.input`

    width: 300px;
    height: 10px;

    outline: none;
    border: none;

    padding: 10px;

    background-color: transparent;

    color: white;

    font-size: 1rem;

`;