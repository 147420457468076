import React, { useState } from 'react';
import styled from 'styled-components';

import MenuItem from '../atom_components/MenuItem';
import Logout from '../atom_components/Logout';
import MenuSetting from '../atom_components/MenuSetting';
import { nowMenuState } from '../state/store';
import { useRecoilValue } from 'recoil';


// 대, 중 카테고리 메뉴 설정 *특이 하위 메뉴가 없는 메뉴 자체 링크 설정
// 각 카테고리 별, 등급 제한 기능 설정

/* 
    id = key
    name = menu name
    list = move to component name
    icon = icon name [ react-icon ]
*/

// 하위 메뉴가 없으며, 클릭 시 페이지 이동이 필요한 카테고리의 경우 list key = component name 값을 넣어주세요.
// 섹션 분리가 필요한 경우, id = 0 으로 지정해주세요. *list 값이 있어도 클릭되지 않습니다.

//  ***  List is Most Unique Value  ***

const project_name = "BIO3S";

const category_list = [

    { id: 1, name: "메인페이지",   list: '',    icon: 'ai/AiFillAppstore' },
    { id: 2, name: "서브페이지",   list: '',    icon: 'ai/AiFillAppstore'  },

];


/* 
    category = categoryMenu id
    id = key
    name = menu name
    list = move to component name
    icon = icon name [ react-icon ]
*/

// 하위 메뉴 설정
// 상기, category id 번호를 넣어줄 경우 하위 메뉴로 포함됩니다.
const subMenu_list = [

    { category:1,   id: 1,      name: "배너관리",            list: "BANNERS",           icon: 'ai/AiFillLayout'  },
    { category:1,   id: 2,      name: "팝업관리",            list: "POPUPS",            icon: 'ai/AiFillSwitcher'  },
    { category:1,   id: 3,      name: "고객문의 관리",        list: "INQUIRY",          icon: 'ai/AiOutlineComment'  },
    { category:2,   id: 4,      name: "연혁 카테고리 관리",    list: "HISTORYCATEGORY",   icon: 'ai/AiOutlineHistory'  },
    { category:2,   id: 5,      name: "발자취(연혁) 관리",     list: "HISTORIES",          icon: 'ai/AiOutlineHistory'  },
    { category:2,   id: 6,      name: "특허목록 관리",        list: "PATENTS",          icon: 'gr/GrDocumentText'  },
    { category:2,   id: 7,      name: "상품관리",            list: "PRODUCTS",          icon: 'fa/FaProductHunt'  },
    { category:2,   id: 8,      name: "인증 및 등록현황",         list: "CERTIFICATES",      icon: 'io/IoIosPhotos'  },
    { category:2,   id: 9,      name: "ESG활동 관리",        list: "ESG",               icon: 'fi/FiAperture'  },
    { category:2,   id: 11,     name: "기업뉴스 관리",        list: "NEWS",             icon: 'bi/BiNews'  },
    { category:2,   id: 12,     name: "공지사항 관리",        list: "NOTICE",           icon: 'bi/BiNews'  },
    { category:2,   id: 13,     name: "SNS 관리",           list: "SNS",                icon: 'ti/TiSocialSkype'  },
    { category:2,   id: 14,     name: "공공조달",           list: "PROCUREMENT",            icon: 'ti/TiSocialSkype'  },

];

let menuScrollTop = 0;

const LnbMenu = () => {

    const [ isTopScroll, setTopScrollEvent ] = useState(false);
    const [ isBottomScroll, setBottomScrollEvent ] = useState(false);

    // Click Title move => /main
    const HomeHandle = () => { window.location.href = "main" }

    const ScrollHandle = (e:any) => {
        
        if( menuScrollTop >= e.target.scrollTop ) {

            setBottomScrollEvent(true);
            if(isBottomScroll) { setTimeout(() => { setBottomScrollEvent(false); }, 2500); }

        } else {

            setTopScrollEvent(true);
            if(isTopScroll) { setTimeout(() => { setTopScrollEvent(false); }, 2500); }
        }

        menuScrollTop = e.target.scrollTop;
    }

    return (
        <Container>

            <CompanyName scroll={isTopScroll}>

                <span onClick={HomeHandle}>{project_name}</span>

            </CompanyName>

            <MenuBox onScroll={ScrollHandle}>

                <MenuItem 
                    category={category_list}
                    subMenu={subMenu_list}
                 />
                 
            </MenuBox>

            <OptionsBox scroll={isBottomScroll}>

                {/* Menu SETTING BOX */}
                <MenuSetting />

                {/* LOGOUT BUTTON */}
                <Logout />

            </OptionsBox>

        </Container>
    );

}

export default LnbMenu;


const Container = styled.div`
    width: 240px;
    min-width: 240px;
    height: 100%;
    // background-color: #1f2325;
    overflow-y: auto; 
    border-right: 0.1px solid #ffffff38;

    display: flex;
    flex-direction: column;

    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
`;

const CompanyName = styled.div< { scroll : boolean }>`

    width: calc(100% - 20px);
    height: 70px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    padding-left: 20px;
    z-index: 9;

    box-shadow : ${props => (props.scroll ? '0px -3px 20px 0px rgb(0 255 255);' : 'none;')}
    
    span {
        display: inline-block;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &:hover {
        cursor: pointer;
    }
`;

const MenuBox = styled.div`
    width: 100%;
    height: calc(100% - 120px);
    overflow-y: auto;
`;

const OptionsBox = styled.div< { scroll : boolean }>`
    width: 100%;
    height: 50px;
    z-index: 9;
    display: flex;
    flex-direction: row;
    box-shadow : ${props => (props.scroll ? '0px 3px 11px 0 rgb(0 255 255);' : 'none;')}
`;