import React, { useState, useEffect } from 'react';
import styled from "styled-components";

import HiddenEyeIcon from '../assets/images/hidden_eye_icon.png';
import ShowEyeIcon from '../assets/images/show_eye_icon.png';
import CloseIcon from '../assets/images/close_icon.png';
import Loading from '../assets/icons/lotate_loading_white.gif';

import { LoginAccess } from '../api/Login';

const Login = () => {

    const [ id,               setIdentification ]   = useState('hello@bio3s.co.kr');
    const [ password,         setPassword ]         = useState('');
    const [ backgroundImage,  setBackgroundImage ]  = useState('');
    const [ isPasswordHidden, setPasswordHidden ] = useState(false);
    const [ isLoginLoading,   setLoginLoading ] = useState(false);

    const onClickHandleClear = () => { setIdentification(''); };
    const onClickIsHidden = () => { isPasswordHidden ? setPasswordHidden(false) : setPasswordHidden(true); }
    const onChangeHandleIdentification = (e:any) => { setIdentification(e.target.value); };
    const onChangeHandlePassword = (e:any) => { setPassword(e.target.value); };
    
    // Login Button Click Event Handler
    const loginHandle = async () => {

        setLoginLoading(true);
        const data = await LoginAccess(id, password);

        if(!data.success) {
            setLoginLoading(false);
            alert("아이디 또는 패스워드를 다시 확인해주세요.");
            return;
        }

        localStorage.setItem("access_token", data.data.token.access_token);
        localStorage.setItem("refresh_token", data.data.token.refresh_token);

        setTimeout(() => {
            window.location.href = "main";    
        }, 300);
    }

    return(
        <Container>            
            <AlignCenterBox>
                <HeaderText>ADMIN</HeaderText>
                <LoginInputBox>
                    <Input 
                        type="text"
                        placeholder="아이디를 입력해주세요"
                        onChange={() => onChangeHandleIdentification}
                        value={id}
                    />
                    <ClearButton
                        src={CloseIcon}
                        onClick={onClickHandleClear}
                    />
                </LoginInputBox>
                <LoginInputBox>
                    <Input 
                        type={isPasswordHidden ? "text" : "password"}
                        placeholder="패스워드를 입력해주세요"
                        onChange={(e) => onChangeHandlePassword(e)}
                        value={password}
                    />
                    {
                        isPasswordHidden ?
                                <HiddenButton
                                    src={ShowEyeIcon}
                                    onClick={onClickIsHidden}
                                />
                                :
                                <HiddenButton
                                    src={HiddenEyeIcon}
                                    onClick={onClickIsHidden}
                                />
                    }

                </LoginInputBox>

                {
                    !isLoginLoading ? 
                        <LoginButton
                            onClick={loginHandle}
                        >
                            LOGIN
                        </LoginButton>
                        :
                        <LoadingButton>
                            <img src={Loading}/>
                        </LoadingButton>
                }
                
            </AlignCenterBox>
        </Container>
    )
}

export default Login;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
`;

// ${props => {
//     if(props.img) {
//         return 'background-image: url(' + props.img + ')';
//     } else {
//         return 'background-color : white;';
//     }
// }}

const AlignCenterBox = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HeaderText = styled.h2`
    margin-bottom: 25px;
    color: white;
    font-weight: bold;
`;

const LoginInputBox = styled.div`
    width: 70%;
    min-width: 300px;
    max-width: 400px;
    min-height: 40px;
    border: 1px solid #3A3C68;
    border-radius: 5px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        border-inline: 3px solid #3A3C68;
        // direction: rtl;
    }
    background-color: white;
`;

const Input = styled.input`
    width: 90%;
    border-radius: 5px;
    outline: none;
    border: none;
    font-size: 16px;
    text-align: center;
    background-color: inherit;
`;

const ClearButton = styled.img`
    right: 0;
    height: 15px;
`;

const HiddenButton = styled.img`
    height: 15px;
`;

const LoginButton = styled.button`
    width: 70%;
    min-width: 300px;
    max-width: 400px;
    min-height: 40px;
    border: none;
    background-color: #3A3C68;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    &:hover {
        background-color: #81A6D3;
    }
`;

const LoadingButton = styled.button`
    width: 70%;
    min-width: 300px;
    max-width: 400px;
    min-height: 40px;
    border: none;
    background-color: #3A3C68;
    color: white;
    font-weight: bold;
    border-radius: 5px;

    img {
        width: 15px;
    }

`;