import { useRecoilState } from "recoil";
import styled from "styled-components";

// 
import { detailData, detailOpen, nowMenuState } from "../state/store";

// 
import { ListType } from "../interface/Interface";

// 
import Banner from "../atom_organism/detail/Banner";
import Certificate from "../atom_organism/detail/Certificate";
import Popup from "../atom_organism/detail/Popup";
import Qna from "../atom_organism/detail/Qna";
import History from "../atom_organism/detail/History";
import HistoryCategory from "../atom_organism/detail/HistoryCategory";
import Patent from "../atom_organism/detail/Patent";
import Product from "../atom_organism/detail/Product";
import Gallery from "../atom_organism/detail/Gallery";
import News from "../atom_organism/detail/News";
import Notice from "../atom_organism/detail/Notice";
import Sns from "../atom_organism/detail/Sns";
import Procurement from "../atom_organism/detail/Procurement";


import { useEffect } from "react";

// 
const Detail = ( data:ListType ) => {

    // select menu
    const [ menuState, setMenuState ] = useRecoilState(nowMenuState);

    // Detil is Open?
    const [ isDetail, setIsDetail ] = useRecoilState(detailOpen);

    // Common Detail Page Close Button
    const detailCloseButtonHandle = () => {

        if(isDetail) {
            if(window.confirm("정말로 닫으시겠습니까?")) {
                window.location.reload();
                setIsDetail(false);
            }
        }
        
    }

    type Key = { [key: string] : any };

    const detailPageList:Key = {

        BANNERS :       <Banner         { ...data } />,
        POPUPS :        <Popup          { ...data } />,
        INQUIRY :       <Qna            { ...data } />,
        HISTORYCATEGORY : <HistoryCategory        { ...data } />,
        HISTORIES :     <History        { ...data } />,
        PATENTS :       <Patent         { ...data } />,
        PRODUCTS :      <Product        { ...data } />,
        CERTIFICATES :  <Certificate    { ...data } />,
        ESG :           <Gallery        { ...data } />,
        NEWS :          <News           { ...data } />,
        NOTICE :        <Notice         { ...data } />,
        SNS :           <Sns            { ...data } />,
        PROCUREMENT :   <Procurement    { ...data } />,   

    }

    return (

        <Container className="detailContainer">

            <CloseButton
                onClick={() => detailCloseButtonHandle()}
            >
                ESC
            </CloseButton>
            
            {detailPageList[menuState]}

        </Container>

    )
}

export default Detail;


const Container = styled.div`

    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;

    background-color: #202125;

    z-index: 9;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    border: 1px solid #ffffff6e;

    overflow-y: auto;

    border-radius: 0 0 5px 5px;

`;

const CloseButton = styled.button`

    width: 50px;
    height: 30px;
    position: absolute;
    right: 5px;
    top: 5px;

    border: none;
    // border: 1px solid black;
    // border-left: 1px solid black;
    // border-bottom: 1px solid black;

    background-color: black;
    font-weight: bold;
    color: white;   
    
    border-radius: 5px;

    z-index: 10;

    &:hover {

        width: 52px;
        height: 32px;
        background-color: red;
        color: white;
        border-radius: 5px;

    }

`;