import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import Detail from "../atom_components/Detail";
import Table from "../atom_components/Table";

import { 
    nowMenuNameState, 
    detailOpen, 
    detailData 
} from "../state/store";


const Content = () => {

    // 현재 선택된 메뉴 이름 출력
    const [ menuNameState, setMenuNameState ] = useRecoilState(nowMenuNameState);
    const [ isDetail, setIsDetail ] = useRecoilState(detailOpen);
    const [ data, setData ] = useRecoilState(detailData);

    return (

        <Container>

            <ContentHeader>

                <span className="menu_name">{menuNameState}</span>

            </ContentHeader>

            <Table />

            { isDetail ? <Detail { ...data! } / > : "" }

        </Container>
    );

}

export default Content;


const Container = styled.div`

    width: (100% - 40px);
    height: calc(100% - 50px);
    background-color: #202125;
    margin-left: 20px;
    margin-right: 20px;

`;

const ContentHeader = styled.div`

    width: 100%;
    height: 65px;
    background-color: transparent;
    // background-color: #222222;

    color: white;

    display: flex;
    flex-direction: row;   
    align-items: center;

    .menu_name {
        font-size: 1.2rem;
        width: 200px;
        min-width: 200px;
        font-weight: bold;
    }

`;