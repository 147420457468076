import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { nowMenuState, detailOpen, detailData } from "../state/store";
import { columns } from "./columns/Columns";
import { ListType } from "../interface/Interface";
import { dateFormatYYYYMMDD } from "../util/DateFormat";

import styled from "styled-components";
import {useViewApi, registerBanner, registerBoard, registerCertificate, registerGallery, registerHistory, registerHistoryCategory, registerPatent, registerPopup, registerProcurement, registerProductId, registerSocials, searchTable } from "../api/Apis";
import Input from "./Input";
import { FiSearch } from 'react-icons/fi';


const Table = () => {

    // 현재 선택된 메뉴 list value 에 해당하는 컴포넌트 호출
    const [ menuState, setMenuState ] = useRecoilState(nowMenuState);

    const [ list, setList ] = useState([]);
    const [ isDetail, setIsDetail ] = useRecoilState(detailOpen);
    const [ detail, setData ] = useRecoilState(detailData);
    const [ inputValue, setInputValue ] = useState<string>('');
    const [ timer, setTimer ] = useState<any>(null);

    const [ data, isError, isLoading ] = useViewApi(menuState);

    const renderColumns = ():JSX.Element => {

        const column = <ColumnHeader>

                            {
                                
                                Object.entries(columns[menuState]).map(

                                    (col, index) => {
                                        
                                        const splitAttribute:string = String(col[1]);
                                        const columnSplit = splitAttribute.split('|');

                                        return  <Column key={index} colWidth={`${ columnSplit[1] }`}> 
                                                    { `${ columnSplit[0] }` }
                                                </Column> 

                                    }
                                    
                                )
                            }
                        </ColumnHeader>

        return column;
        
    }

    const detailHandle = (e:any, detail:ListType) => {

        e.stopPropagation();

        setData(detail);
        setIsDetail(true);

    }

    const search = (e:any) => {

        clearTimeout(timer);

        setInputValue(e.target.value);

        const callApiTimer = setTimeout(() => {
            searchTable(menuState, e.target.value).then((res) => {
                if(res.success) {
                    setList(res.data);
                }
            });
        }, 500);

        setTimer(callApiTimer);

    };

    const registerOnClickHandle = () => {

        switch (menuState) {

            case "PRODUCTS":

                registerProductId().then((res) => { 
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                });

                break;

            case "BANNERS":

                registerBanner().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })
                
                break;
            
            case "POPUPS":

                registerPopup().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })
            
                break;

            case "INQUIRY":

                registerPopup().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })
                
                break;
            
            case "HISTORIES":

                registerHistory().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        ref_id : res.data.categoryId,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })

                break;

            case "HISTORYCATEGORY":

                registerHistoryCategory().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })

                break;

            case "PATENTS":

                registerPatent().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })
                break;
            
            case "CERTIFICATES":

                registerCertificate().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })

                break;

            case "ESG":

                registerGallery().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })
                break;
            
            case "NEWS":

                registerBoard(0).then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })
                break;

            case "NOTICE":

                registerBoard(1).then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })
                break;
            
            case "SNS":
                
                registerSocials().then((res) => {
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        title: "",
                        type: res.data.type,
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })

                break;

            case "PROCUREMENT" :

                registerProcurement().then((res) => {
                    
                    let returnId:ListType = {
                        id: res.data.id,
                        content: "",
                        ref_procurementList_id: res.data.ref_procurementList_id,
                        title: "",
                        type: "",
                        fileAddr: "",
                        created_at: "",
                        file_id: "",
                        category: "",
                        tags: "",
                        sel_link: "",
                        short_content: "",
                        status: ""
                    }
                    setData(returnId); 
                    setIsDetail(true);
                })

                break;

            default:
                
                alert("Miss Matching Column");
                break;

        } // switch end

    };

    // https://www.pluralsight.com/guides/how-to-create-a-right-click-menu-using-react
    const rightEventHandle = (e:any) => {

        e.preventDefault();

        console.log(e.pageX);
        console.log(e.pageY);

    }

    useEffect(() => {

        setInputValue('');

        if(data) { 

            if(data.success) {

                // console.log(data.data);

                if(!inputValue) setList(data.data);
                return;
            }

            if(data.msg) alert(data.msg);

        }

    }, [menuState, data, detail]);


    return (
        <Container>

            <OptionBox>
                
                {
                    menuState !== "HISTORYCATEGORY" ?
                    <SearchBox>
                        <FiSearch />
                        <Input 
                            className=""
                            value={inputValue || ''}
                            placeholder="검색어를 입력해주세요"
                            handleChange={(e) => search(e)}
                        />
                    </SearchBox>
                    :
                        ""

                }

                {
                    menuState !== "INQUIRY" ?

                        <AddButtton onClick={registerOnClickHandle}>
                            등록
                        </AddButtton>
                    :
                        ""
                }
                
            </OptionBox>


            {/* <span>[번호]의 경우 Database 내 해당 데이터의 Primary key Value 입니다.</span> */}
            {renderColumns()}


            {/* 번호, 상세보기는 기본 */}
            {/* 
                정의된 컬럼에 대한 데이터만 가져온다.
                데이터가 없는 경우에 대한 테이블 정의
                
            */}
            {
                list.length > 0 ? 

                    list.map((col:ListType, index) => {

                        return <ColumnHeader className="tableRowData" key={index} onClick={(e) => detailHandle(e, col)}>

                                    {
                                        Object.entries(columns[menuState]).map(

                                            (item, columCount) => {

                                                const splitAttribute:string = String(item[1]);
                                                const columnSplit = splitAttribute.split('|');

                                                let colString:any = "";
                                                  
                                                // 테이블 컬럼별 조정
                                                if(menuState === "BANNERS") {

                                                    if(item[0] === "type") {
                                                        
                                                        colString = col[item[0]] == '0' ? "이미지" : "영상";

                                                    } else if (item[0] === "fileAddr") {
                                                        
                                                        if(col['ext'] === "webm"
                                                        || col['ext'] === "mkv"
                                                        || col['ext'] === "flv"
                                                        || col['ext'] === "vob"
                                                        || col['ext'] === "ogv"
                                                        || col['ext'] === "ogg"
                                                        || col['ext'] === "drc"
                                                        || col['ext'] === "gif"
                                                        || col['ext'] === "gifv"
                                                        || col['ext'] === "mng"
                                                        || col['ext'] === "avi"
                                                        || col['ext'] === "MTS"
                                                        || col['ext'] === "mov"
                                                        || col['ext'] === "wmv"
                                                        || col['ext'] === "mp4"
                                                        || col['ext'] === "mpeg"

                                                        ) {

                                                            colString = <video className="colVideo" controls>
                                                                            <source 
                                                                                src={col[item[0]]}
                                                                                // type="video/mp4"
                                                                            >
                                        
                                                                            </source>
                                                                        </video>

                                                        } else {

                                                            colString = <img className="colImage" src={col[item[0]]} />

                                                        }
                                                        

                                                    } else {

                                                        colString = col[item[0]];  
    
                                                    }

                                                } else if (menuState === "POPUPS") {

                                                    if (item[0] === "fileAddr") {

                                                        colString = <img className="colImage" src={col[item[0]]} />

                                                    } else {

                                                        colString = col[item[0]]; 

                                                    }

                                                } else if (menuState === "INQUIRY") {

                                                    if (item[0] === "status") {

                                                        if(col[item[0]] == '0') {

                                                            colString = <button className="qna">대기</button>

                                                        } else if (col[item[0]] == '1') {

                                                            colString = <button className="qnaComplete">완료</button>

                                                        }
                                                        
                                                    } else {

                                                        colString = col[item[0]]; 

                                                    }

                                                } else if (menuState === "HISTORIES") {
                                                    
                                                    if (item[0] === "status") {

                                                        if(col[item[0]] == '0') {

                                                            // colString = <button className="qna">대기</button>

                                                        } else if (col[item[0]] == '1') {

                                                            // colString = <button className="qnaComplete">완료</button>

                                                        }
                                                        
                                                    } else {

                                                        colString = col[item[0]]; 

                                                    }

                                                    
                                                } else if (menuState === "PRODUCTS") {
                                                    
                                                    if(item[0] === "status") {
                                                        
                                                        if(col[item[0]] == "1") {
                                                            colString = "메인";
                                                        } else if(col[item[0]] == "0") {
                                                            colString = "일반";
                                                        } 

                                                    } else {

                                                        colString = col[item[0]];  
    
                                                    }
                                                    
                                                } else if (menuState === "PATENTS") {
                                                    
                                                    if(item[0] === "type") {

                                                        // 0 : 국내출원, 1 : 국내등록, 2 : 해외출원, 3 : 해외등록

                                                        if(col[item[0]] == "0") {
                                                            colString = "국내출원";
                                                        } else if(col[item[0]] == "1") {
                                                            colString = "국내등록";
                                                        }  else if(col[item[0]] == "2") {
                                                            colString = "해외출원";
                                                        }  else if(col[item[0]] == "3") {
                                                            colString = "해외등록";
                                                        } 

                                                    } else {

                                                        colString = col[item[0]];  
    
                                                    }

                                                } else if (menuState === "CERTIFICATES") {
 
                                                    colString = col[item[0]];  

                                                } else if (menuState === "ESG") {
                                                    
                                                    if (item[0] === "fileAddr") {

                                                        colString = <img className="colImage" src={col[item[0]]} />

                                                    } else if (item[0] === "type") {
                                                        
                                                        if(col[item[0]] == '0') {

                                                            colString = "Echo";

                                                        } else if(col[item[0]] == '1') {

                                                            colString = "Good";
                                                            
                                                        } else if(col[item[0]] == '2') {
                                                            
                                                            colString = "Clean";

                                                        }
                                                        
                                                    } else {

                                                        colString = col[item[0]];  

                                                    }

                                                } else if (menuState === "NEWS") {

                                                    if (item[0] === "status") {

                                                        colString = col[item[0]] == '1' ? "메인" : "일반";

                                                    } else {

                                                        colString = col[item[0]];  

                                                    }

                                                } else if (menuState === "NOTICE") {

                                                    if (item[0] === "status") {

                                                        colString = col[item[0]] == '1' ? "메인" : "일반";

                                                    } else {

                                                        colString = col[item[0]];  

                                                    }
                                                } else if (menuState === "SNS") {
                                                    
                                                    // type. 0:youtube, 1:instagram, 2:products	
                                                    if (item[0] === "fileAddr") {

                                                        colString = <img className="colImage" src={col[item[0]]} />

                                                    } else if (item[0] === "type") {

                                                        if(col[item[0]] == '0') {

                                                            colString = 'youtube';

                                                        } else if(col[item[0]] == '1') {

                                                            colString = 'instagram';

                                                        } else if(col[item[0]] == '2') {

                                                            colString = 'product';
                                                        }
                                                        
                                                    } else {

                                                        colString = col[item[0]];  

                                                    }
                                                    
                                                } else if (menuState === "HISTORYCATEGORY") {

                                                    colString = col[item[0]];

                                                }  else if (menuState === "PROCUREMENT") {

                                                    colString = col[item[0]];

                                                }  

                                                if (item[0] === "created_at") {

                                                    colString = dateFormatYYYYMMDD(col[item[0]]);
                                                    
                                                }

                                                if (item[0] === "content") {
                                                    var content = col[item[0]];
                                                    if(content) colString = content.replace(/(<([^>]+)>)/ig,"");

                                                }   

                                                return  <Column 
                                                            key={columCount} 
                                                            colWidth={`${ columnSplit[1] }`}
                                                            onContextMenu={(e) => rightEventHandle(e)}
                                                        > 
                                                            <span>{colString}</span>

                                                        </Column> 
                                            }
                                        )
                                    }

                                </ColumnHeader>
                    })
                :
                
                <EmptyData>
                    <span>Empty Data</span>
                </EmptyData>
            }

        </Container>
    )

}

export default Table;

const Container = styled.div`
    
    width: 100%;
    overflow-y: auto;
    color: white;
    
    .tableRowData:hover:not(:first-child) {
        background-color: #3e3e3e;
        cursor: pointer;
    
`;

const ColumnHeader = styled.div`

    width: 100%;
    height: 50px;
    min-width: 768px;

    border-top: 1px solid #ffffff6e;
    border-bottom: 1px solid #ffffff6e;

    display: flex;
    flex-direction: row;

    div {
        text-align: center;
    }

    .colImage {
        width: 40px;
        height: 40px;
    }

    .colVideo {
        width: 40px;
        height: 40px;
    }

    .qna {
        width: 100%;
        height: 100%;
        background-color: gray;
        border:none;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        padding : 5px;
    }

    .qnaComplete {
        width: 100%;
        height: 100%;
        background-color: green;
        border:none;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        padding : 5px;
    }
`; 

const Column = styled.div< { colWidth : string } >`

    width : ${props => (props.colWidth ? props.colWidth + ";" : '30px;')}
    height: 100%;

    display:flex;
    justify-content: center;
    align-items: center;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    span {
        
        padding:0 5px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

`;

const EmptyData = styled.div`

    width: 100%;
    height: 120px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-itmes: center;
    
    span {
        color: white;
        font-size: 1.1rem;
        font-weight: bold;
        display: inline-block;
        margin: auto;
    }

    border-bottom: 1px solid #ffffff6e;
    
`;

const OptionBox = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
`;

const SearchBox = styled.div`

    width: 350px;    
    height: 100%;

    text-align: right;
    margin-right: 20px;
    margin-bottom: 20px;

    border: 1px solid #ffffff6e;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

`;

const AddButtton = styled.div`
    width: 70px;
    height: 100%;
    background-color: #f57841;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
`