import { atom } from "recoil";
import { ListType } from "../interface/Interface";

const menu = window.localStorage.getItem('menu');
const name = window.localStorage.getItem('menu_name');
const type = window.localStorage.getItem('language');

// 현재 선택된 메뉴 상태
export const nowMenuState = atom({
    key: 'nowMenuState',
    default: menu ? menu : "BANNERS",
});

// 현재 선택된 메뉴 상태
export const nowMenuNameState = atom({
    key: 'nowMenuNameState',
    default: name ? name : "배너관리",
});

// 디테일 페이지 오픈 여부
export const detailOpen = atom({
    key: 'detailOpen',
    default: false,
})

// 디테일 데이터
export const detailData = atom<ListType | null>({
    key: 'detailData',
    default: null,
})

// 한/영문 변환 기준
export const language = atom({
    key:'language',
    default: type || 'ko',
})