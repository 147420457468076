import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import './App.css';
import Login from './atom_pages/Login';
import Main from './atom_pages/Main';

const App = () => (

  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/main" element={<Main />}></Route>
    </Routes>
  </BrowserRouter>

)

export default App;
