import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import LnbMenu from '../atom_organism/LnbMenu';
import Content from '../atom_organism/Content';
import Header from '../atom_organism/Header';
import { useRecoilState } from 'recoil';
import { detailOpen, language } from "../state/store";

const Main = () => {

    const StorageToken = window.localStorage.getItem('access_token');
    const [ isDetail, setIsDetail ] = useRecoilState(detailOpen);
    const [ token, isTokenSet ] = useState<string>(StorageToken ? StorageToken : "");

    const elementCheck = (e:any) => {
        let mouseClickClass = false;
        if(e) {
            let eventObject = null;
            let eventObjectParent = null;

            eventObject = e.target ? e.target.classList : e.classList;
            eventObjectParent = e.target ? e.target.parentNode : e.parentNode;

            if(eventObject != undefined) {

            if(eventObject.length > 0) {
                eventObject.forEach((item:string) => {
                    if(item === "detailContainer") {
                        return mouseClickClass = true;
                    } else if (item === "tableRowData") {
                        return mouseClickClass = false;
                    }
                });
            }
            } else { 
                return mouseClickClass = false;
            }
            if(!mouseClickClass) elementCheck(eventObjectParent);
        }
        return mouseClickClass;
    }

    const backgroundClick = (e:any) => {        

        if(elementCheck(e)) {

            setIsDetail(false);

        }

    }

    const escKeyHandle = (e:any) => { 

        if(e.keyCode == 27) { 

            if(isDetail) {
                if(window.confirm("정말로 닫으시겠습니까?")) {
                    window.location.reload();
                    setIsDetail(false);
                }
                
            };

        } 
    }

    // Invalid Access Main
    if (!token) {

        alert("Invalid Token Please Login");
        window.location.href = "/";

    } else {

        return  (

            <Container 
                onClick={(e) => backgroundClick(e)}
                onKeyDown={(e) => escKeyHandle(e)}
                tabIndex={0}
            >
                <Row>
                    <LnbMenu />
                    <Column>
                        <Header />
                        <Content />                 
                    </Column>
                </Row>
            </Container>

        )

    }

    
    return (
        <Container/>
    );

};

export default Main;

const Container = styled.div`
    width: 100%;
    height: 100%;

    &:focus {
        outline: 0;
    }
`;

const Row = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const Column = styled.div`
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`;