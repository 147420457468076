import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { 
    boardDetail, 
    deleteBoard, 
    deleteBoardFile, 
    deleteProduct, 
    updateBoard, 
    updateProduct, 
} from "../../api/Apis";

import { ListType } from "../../interface/Interface";
import emptyImage from "../../assets/images/empty_image.png";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import axios, { AxiosError } from "axios";
import { useRecoilState } from "recoil";
import { language } from "../../state/store";

const News = ( data:ListType ) => {


    const [ title, setTitle ] = useState(data.title || '');
    const [ content, setContent ] = useState(data.content || '');
    const [ files, setFiles ] = useState([]);
    const [ type, setType ] = useState(data.type || "");
    const [ uploadFile, setUpladFile ] = useState([]);

    const [ languageType, setLanguage ] = useRecoilState(language);
    
    const typeOnChangeHandle = (e:any) => { setType(e.target.value); }
    const titleOnChangeHandle = (e:any) => { setTitle(e.target.value); }

    const QuillRef  = useRef<ReactQuill>();
    const inputFile = useRef<HTMLInputElement>(null);

    const deleteButtonHandle = () => {  

        if(window.confirm("정말로 삭제하시겠습니까?")) {
            deleteBoard(data.id).then(res => {
                if(res.success) {
                    window.location.href = "/main";
                }
            });
        }

    };

    const saveButtonHandle = () => {  

        /* Call save(update) Api */  
        let updateData:any = [];

        updateData['id']          = data.id;
        updateData['type']        = type;
        updateData['title']       = title;
        updateData['content']     = content;
        updateData['files']       = uploadFile;

        updateBoard(updateData).then(res => {
            if(res.success) {
                window.location.href = "/main";
            }
        });

    };

    const removeFileClick = (item:any) => {

        let filesTempArray:any = [];

        deleteBoardFile(item.id).then((res) => {

            if(res.success) {

                files.map((ele:any) => {
                    if(ele.id !== item.id) {
                        filesTempArray.push(ele);
                    }
                });
        
                setFiles(filesTempArray);
            }
        })
        
    }

    /* --- Editor Image Save API [ to server ] --- */ 
    const imageHandler = () => {

        // 파일을 업로드 하기 위한 input 태그 생성
        const input = document.createElement("input");
        let url = "https://api.bio3s.com/api/"+ languageType +"/register/editor/image";

        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // 파일이 input 태그에 담기면 실행 될 함수 
        input.onchange = async () => {

            const file = input.files;

            if (file !== null) {

                try {

                    const res = axios.post(
                                url, 
                                {   
                                    id: data.id,
                                    type: "editor",
                                    image: file[0],
                                }, 
                                {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                        'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
                                    }
                                }).then((res) => {

                                    url = res.data.data.url;
                                    const range = QuillRef.current?.getEditor().getSelection()?.index;

                                    if (range !== null && range !== undefined) {

                                        let quill = QuillRef.current?.getEditor();

                                        quill?.setSelection(range, 1);
                                        
                                        quill?.clipboard.dangerouslyPasteHTML(
                                        range,
                                        `<img src=${url} />`
                                        );

                                    }
                                });

                    return { ...res, success: true };

                } catch (error) {

                    const err = error as AxiosError;
                    return { ...err.response, success: false };

                }
            }
        };
    };
    
    // react quill init
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { size: ["small", false, "large", "huge"] }, 
                        { color: ["red", "blue", "yellow", "pink", "black"] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    ["image", "video"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
    } },[]);


    // is Video? play
    useEffect(() => { 

        if(!data.id) {
            alert("상품 ID를 확인할 수 없습니다. [News Detail Missing data.id]");
            window.location.href = "/main";
        }

        if(files.length < 1) {
            boardDetail(data.id).then((res) => {
                setFiles(res.data.files);
            });
        }
        
    }, []);


    const openBrowserHandle = (e:any) => {
        if(inputFile.current) { 
            inputFile.current.click(); 
        } 
    }

    // handle file change
    const fileOnChangeHandle = () => {

        if(inputFile.current?.files) {

            let reader = new FileReader();
            let file:any = inputFile.current.files;

            let fileTempArray:any = [];

            if(file){
                for (let index = 0; index < file.length; index++) {
                    fileTempArray.push(file[index]);    
                }
            }

            setUpladFile(fileTempArray);
        }
    }

    const removeUploadFileClick = (item:any) => {

        let fileTempArray:any = [];

        uploadFile.map((ele:any, index:number) => {
            if(ele.lastModified !== item.lastModified) {
                fileTempArray.push(ele);
            }
        })

        setUpladFile(fileTempArray);

    }
    
    return (
        <Container>

            {/* Top Options & Info Section */}
            <Options>

                {/* Delete Button */}
                <DeleteButton
                    onClick={deleteButtonHandle}
                >
                    삭제
                </DeleteButton>

                <SaveButton
                    onClick={saveButtonHandle}
                >
                    저장
                </SaveButton>

                <h4></h4>
            </Options>

            <DataSection>

                {/* Data Id */}
                <Row height={40}>
                    <Col width={20}>고유번호</Col>
                    <Col width={80}>{ data.id }</Col>
                </Row>

                {/* 메인화면 노출 여부 */}
                <Row height={40}>
                    <Col width={20}>상태</Col>
                    <Col width={80}>
                        <select 
                            onChange={(e) => typeOnChangeHandle(e)}
                            defaultValue={type}
                        >
                            <option value="0">일반</option>
                            <option value="1">메인</option>
                        </select>
                    </Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>조회수</Col>
                    <Col width={80}>{data.count}</Col>
                </Row>

                <Row height={40}>
                    <Col width={20}>제목</Col>
                    <Col width={80}>
                        <input 
                            type="text" 
                            onChange={(e) => titleOnChangeHandle(e)} 
                            value={ title }
                        />
                    </Col>
                </Row> 

                <Row height={200}>
                    <Col width={20}>첨부파일</Col>
                    <Col width={80}>
                        <div className="filesContainer">

                            <div className="addButtonSection">
                                <button
                                    onClick={(e) => openBrowserHandle(e)}
                                >
                                    추가
                                </button>
                            </div>

                            <div className="fileList">
                                {
                                    files ? 
                                        files.map((item:any, index:number) => {
                                            return (
                                                <div key={index} className="file">
                                                    <span>{item.origin_filename}.{item.ext}</span>
                                                    <button className="deleteFileBtn" onClick={(e) => removeFileClick(item)}>
                                                        X
                                                    </button>
                                                </div>
                                            )
                                        })
                                    :
                                    ""
                                }

                                {
                                    uploadFile ?
                                        
                                        uploadFile.map((item:any, index:number) => {
                                            return (
                                                <div key={index} className="file">
                                                    <span>{item.name}</span>
                                                    <button className="deleteFileBtn" onClick={(e) => removeUploadFileClick(item)}>
                                                        X
                                                    </button>
                                                </div>
                                            )
                                        })

                                    :
                                        ""
                                }
                                
                            </div>

                        </div>
                    </Col>
                </Row> 

                {/* created_at */}
                <Row height={40}>
                    <Col width={20}>작성일</Col>
                    <Col width={80}>{ data.created_at }</Col>
                </Row>
                
                {/* 상세 내용 */}
                <Row height={800}>
                    <Col width={20}>상세내용</Col>
                    <Col width={80}>
                        <CustomReactQuill 
                            ref={(element) => {
                                if(element !== null) {
                                    QuillRef.current = element;
                                }
                            }}
                            value={ content }
                            onChange={setContent}
                            modules={modules}
                            theme="snow"
                            placeholder="내용을 입력해주세요."
                        />
                    </Col>
                </Row>

            </DataSection>
            
            {/* 상품 대표 이미지 설정 */}
            <input 
                type="file" 
                ref={inputFile} 
                onChange={fileOnChangeHandle}
                style={{display: 'none'}} 
                multiple
            />

        </Container>
    )
}

export default News;

//
const Container = styled.div`

    width: 100%;
    min-width: 500px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

`;

//
const DataSection = styled.div`

    width: 98%;
    height: calc(100% - 40px);
    margin-top: 50px;
    overflow-y: auto;
    padding-top: 40px;
`;

//
const Row = styled.div<{ height: number }>`

    width: 100%;
    min-height: 40px;

    height: ${props => (props.height ? props.height+"px;" : "40px;" )}

    display: flex;
    flex-direction: row;
    
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 1rem;

`;

//
const Col = styled.div<{ width: number }>`

    width: ${props => (props.width ? props.width+"%;" : "10%;" )}

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: thin solid #ffffff6e;

    text-align: center;

    overflow-y: auto;

    .productImageAddBtn {
        width: 170px;
        height: 60px;
        border: none;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: bold;
        background-color: white;
        color: black;
    }

    img {

        width: 320px;
        border-radius: 2px;

    }

    video {

        width: 320px;
        border-radius: 2px;

    }

    textarea {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    input {

        width: 100%;
        height: 100%;

        background-color: #7c4c4c78;
        border: none;
        color: white;

        font-weight: bold;
        font-size: 1rem;
        text-align: center;

        resize: none;

    }

    span {

        margin-bottom: 20px;

    }

    select {

        width: 100%;
        height: 100%;
        border: none;
        text-align: center;
        background-color: #7c4c4c78;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        appearance: none;

    }

    .filesContainer {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: row;

        .addButtonSection {

            width: 120px;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            button {

                width: 70%;
                height: 30px;

                border: none;
                border-radius: 5px;
                background: #7676b6;
                color: white;

                font-weight: bold;
                font-size: 1rem;

                &:hover {
                    cursor: pointer;
                }

            }
        }

        .fileList {

            width: calc( 100% - 120px );
            overflow-y: auto;

            .file {
                
                display : flex;
                flex-direction: row;

                width: 100%;
                min-height: 40px; 
                margin-left: 5px;
                margin-top: 10px;
                
                span {
                    margin-right: 5px;
                }

                button {
                    width: 20px;
                    height: 20px;
                    border: none;
                    border-radius: 5px;
                    background-color: red;
                    color: white;
                    font-weight: bold;
                    margin-right: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

        }
    }

    .addBtn {
        width: 40%;
        height: 30px;
        margin-bottom: 5px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;

        font-weight: bold;
        font-size: 1rem;

        &:hover {
            cursor: pointer;
        }
    }

    .faqsContainer {

        width: 100%;
        height: 100%;
    
        .faqsBox {

            border: thin solid black;
            width: 100%;
            height: 200px;

            display: flex;
            flex-direction: row;

            margin-bottom: 5px;

            .label {

                width: 120px;
                display: flex;
                flex-direction: column;

                align-items: center;
                justify-content: center;

                background-color: black;

                button {

                    width: 60%;
                    height: 30px;
                    background-color: red;
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                    }
                }

            }

            .faqList {
                
                width: calc(100% - 120px);
                height: 200px;
                
                .faqs {
                    width: 100%;
                    height: 49%;
                    text-align: left;
                    padding-left: 5px;
                }
            }
            
        }

    }

    .certificateContainer {

        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;

        .selectList {

            color: white;

            width: 100%;
            height: 50%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-bottom: thin solid white;
            }

            span {
                
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 90%;
                
            }

            .listBox {
                
                width: 100%;
                height: 100%;
                overflow: auto;

                .list {

                    width: min-content;
                    min-width: 100%;
                    height: 100%;
                    
                    display: flex;
                    flex-direction: row;

                    justify-content: center;
                    align-items: center;

                    .certificateImage {

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        margin-left: 5px;
                        margin-right: 5px;

                        position: relative;

                        width: 240px;
                        min-width: 240px;
                        height: 90%;

                        img {
                            width: 100%;
                            height: 80%;

                        }

                        .selectbtn {
                            
                            width: 90px;
                            height: 30px;

                            border:none;
                            border-radius: 5px;
                            background-color: white;
                            color: black;
                            font-weight: bold;
                            font-size: 1rem;

                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            z-index: 9;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .deleteBtn {

                            width: 90px;
                            height: 30px;

                            border:none;
                            border-radius: 5px;
                            background-color: red;
                            color: white;
                            font-weight: bold;
                            font-size: 1rem;

                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            z-index: 9;

                            &:hover {
                                cursor: pointer;
                            }

                        }

                        .certificateImageBg {

                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            width: 240px;
                            min-width: 240px;
                            height: 80%;

                            background: gray;
                            opacity: 0.8;

                            display: flex;
                            white-space: pre-line;
                            
                            justify-content : center;
                            align-items: center;

                        }
                    }
                }
            }
        }

    }

    .snsContainer {

        width: 100%;
        height: 100%;

        .snsBox { 

            width: 100%;
            height: 140px;
            position: relative;

            display: flex;
            flex-direction: row;

            margin-bottom: 10px;

            .snsDelete {

                width: 120px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: black;

                button { 
                    width: 60%;
                    height: 30px;
                    background-color: red;
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-weight: bold;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .snsData {

                width: calc(100% - 120px);

                input {
                    height: calc(100% / 3);
                    font-size: 1rem;
                }

                .snsFile {

                    text-align: left;
                    width: 100%;
                    height: calc(100% / 3); 
                    padding-left: 20px;
                    
                    color: white;
                    font-size: 1rem;
                    font-weight: bold;

                    background-color: gray;

                    &:hover {
                        cursor: pointer;
                    }

                }

                .snsFileUploadBox {
                    
                    display: flex;
                    align-items: center;

                    width: 100%;
                    height: calc(100% / 3); 
                    color: white;
                    font-size: 1rem;
                    font-weight: bold;

                    background-color: gray;

                    input {
                        display: inline;
                        width: 100%;
                        height: auto;
                        background-color: transparent;
                        margin-left: 10px;
                    }

                    .initBtn {
                        border:none;
                        border-radius: 5px;
                        width: 60px;
                        height: 25px;
                        font-size: 0.9rem;
                        font-weight: bold;
                        background-color: #7c4a7f;
                        margin-left: 10px;
                        color: white;

                        &:hover {
                            cursor: pointer;
                        }

                    }

                }
            }

        }

    }
    
`;

// 
const Options = styled.div`

    width: 100%;
    height: 40px;
    min-height: 40px;
    position: fixed;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: white;

    h4 {
        margin-left: 20px;
        color: red;
    }
`;

//
const DeleteButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #43424c;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;

//
const SaveButton = styled.button`

    width: 100px;
    height: 70%;
    background-color: #4c486e;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
        cursor: pointer;
    }

`;

//
const CustomReactQuill = styled(ReactQuill)`

    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    border:none;

    .quill {
        background-color: gray;
    }

    .ql-container {
        border: none;
    }
    
    .ql-toolbar {
        display: flex;
        fill: white;
        background-color: white;
    }

    .ql-editor {
        background-color: white;
        color: black;
        font-weight: normal;
    }

`;